import { client, formatQueryParams } from 'util/sdk';

export const load = ({ fspId, payerId, ...params }) =>
  client
    .fsps(fspId)
    .payers(payerId)
    .transactionHistory.query(formatQueryParams(params));

export const loadById = ({ fspId, id }) =>
  client.fsps(fspId).transactionHistory(id).get();

export const loadByQueryParams = ({ fspId, ...params }) =>
  client.fsps(fspId).transactionHistory.query(formatQueryParams(params));

export const decryptRDFIAccountNumber = ({ fspId, id }) =>
  client.fsps(fspId).transactionHistory.query(
    formatQueryParams({
      attributes: ['coreAccountNumber', 'extDdaAccountNbr'],
      id,
    })
  );

export const decryptDestination = (transaction) =>
  client
    .fsps(transaction.fspId)
    .transactionHistory(transaction.id)
    .decryptDestinationAccountNumber(transaction);

export const decryptFunding = (transaction) =>
  client
    .fsps(transaction.fspId)
    .transactionHistory(transaction.id)
    .decryptFundingAccountNumber(transaction);

export const updateReturnedStatus = ({ fspId, id, ...params }, auth) =>
  client
    .fsps(fspId)
    .transactionHistory(id)
    .updateReturnedStatus(params, { adminUserId: auth.id });

export const updatePaymentNor = ({ fspId, id, returnedCode }, auth) =>
  client
    .fsps(fspId)
    .transactionHistory(id)
    .updateReturnedA2as({ returnedCode }, { adminUserId: auth.id });

export const updatePaymentNoc = ({ fspId, id, ...data }, auth) =>
  client
    .fsps(fspId)
    .transactionHistory(id)
    .updateReturnedA2aNOCs(data, { adminUserId: auth.id });
