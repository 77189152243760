import {
  ADMIN_USER_LOCKED_API_ERROR,
  ADMIN_USER_INACTIVE_API_ERROR,
  INVALID_LOGIN_CREDENTIAL_API_ERROR,
  PASSWORD_REQUIREMENTS,
  RECYCLED_PASSWORD_API_ERROR,
} from 'apiErrors';
import { CLEAR_UPDATE } from 'reducers/authToken';
import {
  HTTP_STATUS_CODE_403,
  HTTP_STATUS_CODE_404,
  HTTP_STATUS_CODE_412,
  HTTP_STATUS_CODE_426,
  OTP_REQUIRED_ERROR,
} from 'consts';
import { ORM } from 'rootReducer';
import { all, call, put, select } from 'redux-saga/effects';
import { findIndex, groupBy, map, mergeRight, prop, propEq } from 'ramda';
import { startRequestTimer } from 'reducers/metrics';
import { startSubmit, stopSubmit } from 'redux-form';
import {
  SUCCESS,
  FAILURE,
  createActionSuccess,
  ADD_MESSAGE,
  UNAUTH,
  UNAUTHORIZED,
} from './actions';
import { getAuth } from './auth';

export const getError = (response, message, rawError) => {
  let error;

  if (response && response.data) {
    error = response.data.error;

    if (response.status === HTTP_STATUS_CODE_404) {
      error = null;
    } else if (!error) {
      if (response.status === HTTP_STATUS_CODE_404) {
        error = null;
      } else if (response.status === HTTP_STATUS_CODE_426) {
        error = {
          message: 'Ontrac needs to be upgraded',
          number: HTTP_STATUS_CODE_426,
          versionRequired: response.headers.upgrade,
        };
      } else if (response.status >= 500) {
        error = { message: 'Internal Error' };
      } else if (response.status >= 400) {
        error = { message: 'Server error, please contact support' };
      }
    }
  } else {
    error = message ? { message } : response;
  }

  if (error && rawError) {
    error = { ...error, ...rawError };
  }

  return { ...error, req: response?.config?.url, status: response?.status };
};

const getErrorFromNumber = ({ message, number }, form) => {
  let errorMessage = message;

  switch (number) {
    case '020015':
      errorMessage = 'Server error, please contact support';
      break;
    case INVALID_LOGIN_CREDENTIAL_API_ERROR:
      errorMessage =
        form === 'authenticateChange'
          ? 'Wrong password - try again'
          : 'Wrong password or email, try again';
      break;
    case ADMIN_USER_INACTIVE_API_ERROR:
    case ADMIN_USER_LOCKED_API_ERROR:
      errorMessage =
        'Your account has been locked.  Please contact the administrator to unlock your account.';
      break;
    case PASSWORD_REQUIREMENTS:
      errorMessage = 'Password does not meet all requirements';
      break;
    case RECYCLED_PASSWORD_API_ERROR:
      errorMessage =
        'Password cannot be the one you have used in the last year.  Please change to a different password.';
      break;

    default:
      break;
  }

  return errorMessage;
};

const getFormErrors = (responseError, form) => {
  const error = {
    _error: 'Verification failed',
  };

  if (responseError) {
    const errorMessage = getErrorFromNumber(responseError, form);
    if (
      responseError?.number === PASSWORD_REQUIREMENTS ||
      responseError?.number === RECYCLED_PASSWORD_API_ERROR
    ) {
      error.newPassword = errorMessage;
      // don't capture OTP errors
    } else if (responseError.number !== OTP_REQUIRED_ERROR) {
      error.password = errorMessage;
    }
  } else {
    error.password = 'Unable to login, contact support';
  }

  return error;
};

const getResultData = (data, dataName) => {
  if (Array.isArray(data)) {
    return data;
  }
  if (dataName && data[dataName]) {
    return data[dataName];
  }
  return data;
};

const getMergeResultsByKey = (data, apis) => {
  let retVal = [];
  const values = [];

  data.forEach((element, index) => {
    values.push(element[apis[index].dataName]);
  });

  if (values.length >= 2) {
    const first = groupBy(prop(apis[0].key), values[0]);

    retVal = map(
      (value) => mergeRight(value, first[value[apis[1].key]][0]),
      values[1]
    );
  }

  return retVal;
};

/**
 *  Why is this so convoluted? Because 3 different "styles" of response may be returned:
 *    1. Directly from the SDK = i.e. createAccessToken
 *    2. From the Ontrac server, i.e. login
 *    3. From the API directly, i.e. fsps
 *
 * @param e error thrown
 * @returns {{rawError, response: {}, message, statusCode}}
 */
const getDetails = (e) => {
  let { error: rawError, message, statusCode, response = {} } = e;

  if (e.response) {
    statusCode = e.response.status;
    message = e.response.statusText;
  }

  if (!e.response && e.data) {
    response = e.data;
    rawError = e.data.error;
  }

  return { message, rawError, response, statusCode };
};

export const callAction = ({
  api,
  dataName,
  formatErrorMessage,
  formatSuccessMessage,
  postAction,
  prepareData,
  type,
}) => {
  return function* worker({ data = {}, form, meta }) {
    const timer = startRequestTimer(type.ACTION);
    const callData = prepareData ? prepareData(data) : data;
    let result;

    try {
      if (form) {
        yield put(startSubmit(form));
      }

      const auth = yield select(getAuth);

      if (Array.isArray(api)) {
        const calls = map((entry) => call(entry.api, callData, auth), api);

        result = yield all(calls);

        result = {
          props: { ...callData },
          response: getMergeResultsByKey(result, api),
        };
      } else {
        result = yield call(api, callData, auth);

        if (dataName && dataName !== ORM) {
          result = getResultData(result, dataName);
        } else if (dataName === ORM) {
          result = {
            props: { ...callData },
            response: result,
          };
        }
      }

      if (postAction) {
        result = yield postAction({ data, result });
      }

      yield put(createActionSuccess(type)(result, callData, meta));

      if (formatSuccessMessage) {
        yield put({
          type: ADD_MESSAGE.ACTION,
          data: formatSuccessMessage(result, callData),
        });
      }

      if (form) {
        // Important - 'false' is what tells that verification is complete
        yield put(stopSubmit(form, { _error: 'false' }));
      }

      yield put(timer.finish(SUCCESS, type));
    } catch (e) {
      const { message, statusCode, rawError, response } = getDetails(e);

      if (formatErrorMessage) {
        yield put({
          type: ADD_MESSAGE.ACTION,
          data: formatErrorMessage(e, callData),
        });
      }

      if (statusCode === HTTP_STATUS_CODE_403) {
        yield put({
          type: UNAUTHORIZED.ACTION,
          data: {
            authToken: {
              error: rawError,
              unauthorized: true,
            },
          },
        });
        yield put(timer.finish(UNAUTH));
        return result;
      }

      if (statusCode === HTTP_STATUS_CODE_412) {
        // The token was not valid, take the user back to the login page
        yield put({ type: CLEAR_UPDATE.ACTION });
        yield put(timer.finish(UNAUTH));
        return result;
      }

      const error = getError(response, message, rawError);

      if (error) {
        yield put({ data, error, type: type.FAILURE });

        if (form) {
          yield put(stopSubmit(form, getFormErrors(error, form)));
        }

        if (data.onError) {
          data.onError(error);
        }

        yield put(timer.finish(FAILURE));
        return result;
      }

      // No error, not sure what to do
      yield put(
        createActionSuccess(type)(
          dataName === ORM ? { props: { ...callData }, response: [] } : []
        )
      );

      yield put(timer.finish(SUCCESS));
    }

    return result;
  };
};

export const saga =
  ({ ADD, DELETE, LOAD, UPDATE, initialState = [] }) =>
  (state = initialState, { data, type }) => {
    switch (type) {
      case ADD?.SUCCESS:
        return [...state, data];

      case DELETE?.SUCCESS: {
        const { id } = data;
        const index = findIndex(propEq('id', id), state);
        if (index === -1) {
          return state;
        }
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }

      case LOAD?.SUCCESS:
        return data;

      case UPDATE?.SUCCESS: {
        const { id } = data;
        const index = findIndex(propEq('id', id), state);
        if (index === -1) {
          return state;
        }
        return [...state.slice(0, index), data, ...state.slice(index + 1)];
      }

      default:
        return state;
    }
  };
