import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectorIdWithLocation } from 'selectors/payee';
import { transactOnBehalfBPOnSelector } from 'selectors/userFspConfig';
import { load } from 'model/payeeAudit';
import { selectorCount, selectorGroupedByDays } from 'selectors/payeeAudit';
import TransactAsButton from 'components/modules/service/PayerDetail/TransactAsButton';
import { PAYEE_STATUS_ACTIVE } from 'consts';
import Audit from '../../../Audit';
import AuditDetailRowRecord from '../../../Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from '../../../Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../../Audit/config/shared/AuditDrawerAppBarTitle';
import EBillRefreshStatementButton from '../eBill/RefreshStatementButton';

const ActionsRenderer = ({ item }) => {
  const transactBPOn = useSelector((state) =>
    transactOnBehalfBPOnSelector(state, item?.fspId, item?.payerId)
  );

  const showTransactOnBehalf =
    item?.payeeStatus === PAYEE_STATUS_ACTIVE && transactBPOn;

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <EBillRefreshStatementButton
        ebillEnrollAt={item.ebillEnrollAt}
        fspId={item.fspId}
        id={item.payerEbillernetEbillerId}
      />
      {showTransactOnBehalf && (
        <Grid item>
          <TransactAsButton payeeId={item.id} payerId={item.payerId} />
        </Grid>
      )}
      <Grid item>
        <Audit
          action={load}
          countSelector={selectorCount}
          DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
          item={item}
          Row={AuditDetailRowRecord}
          selectorGroupedByDays={selectorGroupedByDays}
          selectorId={selectorIdWithLocation}
          Title={AuditDrawerAppBarTitle}
        />
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
