import AuditContext from 'components/modules/service/Audit/AuditContext';
import { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';

const AuditDrawerAppBarTitle = () => {
  const {
    item: { netUserIdStr },
  } = useContext(AuditContext);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography color="inherit" variant="h6">
          Audit Details - {netUserIdStr || ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuditDrawerAppBarTitle;
