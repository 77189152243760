import { attr, oneToOne, Model } from 'redux-orm';
import {
  createAction,
  createLoadConst,
  createRequestConst,
  createDeleteConst,
} from '../util';
import { derived } from './util/fspFraudTrans';
import SchedTranFraud from './schedTranFraud';

export default class FSPFraudTrans extends Model {
  static get modelName() {
    return 'FSPFraudTrans';
  }

  static reducer({ type, data }, FSPFraudTrans) {
    switch (type) {
      case CHECK_ALL.ACTION:
        FSPFraudTrans.update({ selected: data.selected });
        break;

      case CHECK.ACTION: {
        const fSPSchedTrans = FSPFraudTrans.withId(data.id);

        if (fSPSchedTrans) {
          fSPSchedTrans.selected = !fSPSchedTrans.selected;

          fSPSchedTrans.update(fSPSchedTrans);
        }
        break;
      }

      case CLEAR.ACTION:
        FSPFraudTrans.delete();
        break;

      case CLEAR_NEXT.ACTION:
        FSPFraudTrans.update({ isNextDerived: false });
        break;

      case LOAD.SUCCESS:
        FSPFraudTrans.delete();
        data?.response?.forEach((r) => {
          FSPFraudTrans.upsert(derived(r));
        });
        break;

      case REMOVE.ACTION:
        FSPFraudTrans.all()
          .filter((item) => item[data.field] === data.id)
          .delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      accountCreatedAt: attr(),
      accountCreatedAtDerived: attr(), // derived
      accountCreatedOnDerived: attr(), // derived
      amount: attr(),
      amountDerived: attr(), // derived
      autopayRuleId: attr(),
      canceledAt: attr(),
      canceledAtDerived: attr(), // derived
      canceledBy: attr(),
      canceledOn: attr(),
      canceledReason: attr(),
      checkMemo: attr(),
      confirmationNumber: attr(),
      createdAt: attr(),
      createdAtDerived: attr(), // derived
      ddaId: attr(),
      deliver: attr(),
      deliverOn: attr(),
      deliverOnDerived: attr(), // derived
      deliveryCount: attr(),
      deliveryCountDerived: attr(), // derived
      description: attr(),
      destination: attr(),
      destinationLastUpdatedOn: attr(),
      destinationMask: attr(),
      disburseType: attr(),
      disburseTypeDerived: attr(), // derived
      executionStatus: attr(),
      externalDdaId: attr(),
      feeAmount: attr(),
      foreverOffOn: attr(),
      fraudId: oneToOne(SchedTranFraud.modelName, 'schedTranFrauds'),
      fraudScore: attr(),
      frequency: attr(),
      frequencyLabel: attr(), // derived
      fspAdminIdStr: attr(),
      fspId: attr(),
      fspPayerIdMask: attr(),
      fundingAccount: attr(),
      fundingAccountType: attr(),
      fundingMask: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      ipv4: attr(),
      isNextDerived: attr(), // derived
      lockAt: attr(),
      microService: attr(),
      modelId: attr(),
      offset: attr(),
      onholdApprovedAt: attr(),
      onholdApprovedBy: attr(),
      onholdAt: attr(),
      onholdBy: attr(),
      onholdMaxMinutesAt: attr(),
      onholdMaxMinutesAtDateDerived: attr(), // derived
      onholdMaxMinutesAtDerived: attr(), // derived
      onholdReason: attr(),
      p2pDirectOffOn: attr(),
      payeeId: attr(),
      payerId: attr(),
      payerName: attr(),
      payerStatus: attr(),
      recipientId: attr(),
      recipientNote: attr(),
      recipientNoteEncoded: attr(),
      resultReasonDerived: attr(), // derived
      reviewedAt: attr(),
      reviewedAtDerived: attr(), // derived
      reviewedBy: attr(),
      reviewedOn: attr(),
      schedDeliverOn: attr(),
      schedSendOn: attr(),
      securityAnswer: attr(),
      securityQuestion: attr(),
      sendOn: attr(),
      sendOnDerived: attr(), // derived
      sendOrDeliver: attr(),
      status: attr(),
      statusDerived: attr(), // derived
      transactByAdmin: attr(),
      transactByAdminAt: attr(),
      transactionType: attr(),
      transactionTypeDerived: attr(), // derived
      transferToDdaId: attr(),
      updAdminUserId: attr(),
      updUser: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = FSPFraudTrans.modelName;

export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const CLEAR = createRequestConst(`${name}_clear`);
export const CLEAR_NEXT = createRequestConst(`${name}_clear_next`);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(`${name}_remove`);

export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const clear = createAction(CLEAR);
export const clearNext = createAction(CLEAR_NEXT);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
