import {
  A2A_IN_OFF_ON,
  A2A_OUT_OFF_ON,
  BP_OFF_ON,
  DEFAULT_MODEL_REF,
  DEFAULT_MODEL_REF_ARRAY,
  P2P_OFF_ON,
  POSSIBLE_PRODUCTS,
} from 'consts';
import { isOn } from 'util/index';

export const combinerProducts = (fspConfig) => {
  if (!fspConfig) return DEFAULT_MODEL_REF_ARRAY;
  return (
    POSSIBLE_PRODUCTS.map(({ type, ...props }) => ({
      enabled: isOn(fspConfig[type]),
      type,
      ...props,
    })) ?? DEFAULT_MODEL_REF_ARRAY
  );
};
export const combinerSelectorArray = (r) => r ?? DEFAULT_MODEL_REF;
export const filterByFSPId = ({ FSPConfig }, fspId) => {
  return FSPConfig.filter((r) => r.fspId === fspId)?.last()?.ref;
};

export const productEnabledCombiner = (fspConfig = {}, key = '') => {
  return Object.keys(fspConfig).length >= 1 ? isOn(fspConfig[key]) : undefined;
};
export const productEnabledA2A = (r) => {
  return (
    productEnabledCombiner(r, A2A_IN_OFF_ON) ||
    productEnabledCombiner(r, A2A_OUT_OFF_ON)
  );
};
export const productEnabledBillPay = (r) => {
  return productEnabledCombiner(r, BP_OFF_ON);
};
export const productEnabledP2P = (r) => productEnabledCombiner(r, P2P_OFF_ON);
