import { loadFSPProfile } from 'model/export';
import {
  CREATE,
  LOAD,
  LOAD_ID,
  UPDATE,
  UPDATE_PREVIEW_SSO,
  PREVIEW_SSO,
  UPDATE_PREVIEW_RECIPIENT,
  PREVIEW_RECIPIENT,
} from 'model/fspConfig';
import { takeEvery, put } from 'redux-saga/effects';
import { create, load, loadById, update } from 'sdk/fspConfig';
import { registerPayer } from 'sdk/payer';
import { LOAD_PAYER } from 'model/viewAs';
import {
  callAction,
  createActionFailure,
  createActionSuccess,
  ORM,
} from '../util';
import {
  formatSuccessMessage,
  FSP_CONFIG_LABEL,
  FSP_NAME_CODE,
  SSO_OVERWRITE_PREVIEW,
  UPDATED,
} from '../consts';

const keyName = { key: FSP_NAME_CODE, name: FSP_CONFIG_LABEL };

export const createFspConfig = () =>
  callAction({
    api: create,
    dataName: ORM,
    formatSuccessMessage: () => `FSP Config has been successfully created`,
    type: CREATE,
  });

function* updateAndPopulateExport(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, UPDATED),
    type: UPDATE,
  });

  if (yield updateAction(action)) {
    yield put(loadFSPProfile({ fspId: action?.data?.fspId }));
  }
}

const updatePreviewRecipient = callAction({
  api: update,
  dataName: ORM,
  type: UPDATE_PREVIEW_RECIPIENT,
});

const updatePreviewSsoAction = callAction({
  api: update,
  dataName: ORM,
  type: UPDATE_PREVIEW_SSO,
});

const registerAction = callAction({
  api: registerPayer,
  dataName: ORM,
  type: LOAD_PAYER,
});

function* previewSso(action) {
  const { fspConfig, payerId } = action.data;

  const overwritePreview = fspConfig?.ssoConfig?.[SSO_OVERWRITE_PREVIEW];
  if (overwritePreview && typeof overwritePreview === 'object') {
    fspConfig.ssoConfig[SSO_OVERWRITE_PREVIEW] =
      JSON.stringify(overwritePreview);
  }

  const result = yield updatePreviewSsoAction({ data: fspConfig });
  if (result) {
    yield registerAction({ data: { fspId: fspConfig.fspId, payerId } });
    yield put(createActionSuccess(PREVIEW_SSO)());
  } else {
    yield put(createActionFailure(PREVIEW_SSO)());
  }
}

function* previewRecipient(action) {
  const { fspConfig } = action.data;

  const overwritePreview = fspConfig?.ssoConfig?.[SSO_OVERWRITE_PREVIEW];
  if (overwritePreview && typeof overwritePreview === 'object') {
    fspConfig.ssoConfig[SSO_OVERWRITE_PREVIEW] =
      JSON.stringify(overwritePreview);
  }

  const result = yield updatePreviewRecipient({ data: fspConfig });
  if (result) {
    yield put(createActionSuccess(PREVIEW_RECIPIENT)());
  } else {
    yield put(createActionFailure(PREVIEW_RECIPIENT)());
  }
}

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(CREATE.ACTION, createFspConfig);
  yield takeEvery(UPDATE.ACTION, updateAndPopulateExport);
  yield takeEvery(PREVIEW_RECIPIENT.ACTION, previewRecipient);
  yield takeEvery(PREVIEW_SSO.ACTION, previewSso);
}
