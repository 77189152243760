import Payer from 'model/payer';
import { attr, fk, Model } from 'redux-orm';
import { createAction, createLoadConst, createUpdateConst } from '../util';
import { addDerivedData as addDerivedDataForPayer } from '../util/payer';
import { addDerivedData, hasPayer } from './util/internalAccount';

export default class InternalAccount extends Model {
  static get modelName() {
    return 'InternalAccount';
  }

  static reducer({ type, data }, InternalAccount, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data?.props?.payerId)) {
          Payer.withId(data.props.payerId).internalAccounts.delete();
        }

        if (data.response) {
          data.response.forEach(({ payer, ...r }) => {
            InternalAccount.upsert(addDerivedData(r));

            if (hasPayer(data.props)) {
              Payer.upsert(addDerivedDataForPayer(payer));
            }
          });
        }
        break;

      case LOAD_ID.SUCCESS:
      case UPDATE.SUCCESS:
        InternalAccount.upsert(addDerivedData(data?.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      accountAchNbrMask: attr(),
      accountMicrNbrMask: attr(),
      accountNameDerived: attr(), // Derived, added 01/20/2022
      accountNbrMask: attr(),
      accountSuffix: attr(),
      accountTitle: attr(), // added 01/20/2022
      achRtn: attr(),
      autopayRuleAt: attr(),
      availBalance: attr(),
      availBalanceDerived: attr(), // Derived
      createdAt: attr(),
      ddaBillPayFromOffOn: attr(),
      ddaP2PPayFromOffOn: attr(), // added 01/20/2022
      ddaStatus: attr(),
      ddaStatusAt: attr(),
      ddaStatusAtDerived: attr(), // Derived
      ddaStatusText: attr(),
      ddaTransferFromOffOn: attr(),
      ddaTransferToOffOn: attr(),
      ddaType: attr(),
      ddaTypeDerived: attr(), // Derived
      description: attr(),
      descriptionDerived: attr(), // Derived
      displayAccountNbrMaskLong: attr(), // added 01/20/2022
      displayAccountNbrMaskShort: attr(), // added 01/20/2022
      fract_rtn: attr(),
      fsp: attr(),
      fspDdaIdMask: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      ledgerBalance: attr(),
      micrRtn: attr(),
      nickName: attr(),
      offset: attr(),
      payer: attr(),
      payerId: fk(Payer.modelName, 'internalAccounts'),
      updatedAt: attr(),
      uspsAddress: attr(),
      wireRtn: attr(),
    };
  }
}

const name = InternalAccount.modelName;

export const LOAD = createLoadConst(name);
export const LOAD_ID = createLoadConst(`${name}_id`);
export const UPDATE = createUpdateConst(name);

export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const update = createAction(UPDATE);
