import { client, formatQueryParams } from 'util/sdk';
import { ON } from 'consts';

export const load = ({ fspId, payerId, ...params }) => {
  if (params.sendOnRange && params.sendOnRange[0] === '') {
    params.sendOnRange[0] = undefined;
  }

  return client
    .fsps(fspId)
    .payers(payerId)
    .scheduledTransactions.query(
      formatQueryParams({
        ...params,
        expand: ['dda'],
        suspendedP2p: ON,
      })
    );
};

export const decryptDestination = async (transaction) =>
  client
    .fsps(transaction.fspId)
    .scheduledTransactions(transaction.id)
    .decryptDestinationAccountNumber(transaction);

export const decryptFunding = async (transaction) =>
  client
    .fsps(transaction.fspId)
    .scheduledTransactions(transaction.id)
    .decryptFundingAccountNumber(transaction);
