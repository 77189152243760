import 'moment-timezone';
import moment from 'moment';
import './businessDays';

export const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY';
export const DEFAULT_TIME_FORMAT = 'LT [ET]';
export const DEFAULT_TIME_ZONE = 'America/New_York';

moment.defaultFormat = DEFAULT_DATE_FORMAT;
moment.tz.setDefault(DEFAULT_TIME_ZONE);
