import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { load } from 'model/userProfile';
import { loadById } from 'model/user';
import { makeStyles } from '@material-ui/core/styles';
import { selectorAllFields as adminSelector } from 'selectors/userProfile';
import { selectorId } from 'selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useFSPId } from 'hooks';
import IdToDisplayNameProgress from './IdToDisplayNameProgress';

const useStyles = makeStyles((theme) => ({
  buttonDisabled: {
    color: theme.palette.text.primary,
  },
  buttonText: {
    fontSize: theme.typography.body1.fontSize,
  },
  buttonTextUnderlined: {
    textDecoration: 'underline',
  },
}));

const IdToDisplayName = ({ adminId, showUserName, systemUser, userId }) => {
  const id = adminId || userId;
  const [buttonClicked, setButtonClicked] = useState(false);
  const [disabledState, setDisabledState] = useState(false);
  const [label, setLabel] = useState(id || systemUser);
  const [target, setTarget] = useState(null);
  const adminUser = useSelector((state) => adminSelector(state, id));
  const classes = useStyles();
  const dispatch = useDispatch();
  const fspId = useFSPId();
  const ref = useRef();
  const user = useSelector((state) => selectorId(state, id));

  const { isEmpty, name } = user;
  const { isEmpty: adminIsEmpty, name: adminName } = adminUser;
  const disabled =
    !Number.isInteger(id) || !isEmpty || !adminIsEmpty || disabledState;
  const underlined = Number.isInteger(id) && isEmpty && adminIsEmpty;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!buttonClicked) {
      setButtonClicked(true);
      setDisabledState(true);
      setTarget(event.target);
    }
  };

  useEffect(() => {
    if (adminId && adminName) {
      setLabel(`${adminName} (${id})`);
    } else if (name) {
      setLabel(`${name} (${id})`);
    }
  }, [adminName, adminId, id, name]);

  useEffect(() => {
    if (buttonClicked || showUserName) {
      if (adminId) {
        dispatch(load(adminId));
      } else if (userId) {
        dispatch(loadById({ fspId, id: userId }));
      }
    }
  }, [adminId, buttonClicked, dispatch, fspId, showUserName, userId]);

  return (
    <Button
      ref={ref}
      classes={{
        label: classNames(classes.buttonText, {
          [classes.buttonTextUnderlined]: underlined,
          [classes.buttonDisabled]: disabled,
        }),
      }}
      disabled={disabled}
      onClick={handleClick}
    >
      {label}
      <IdToDisplayNameProgress buttonRef={ref.current} target={target} />
    </Button>
  );
};

IdToDisplayName.propTypes = {
  adminId: PropTypes.number,
  showUserName: PropTypes.bool,
  systemUser: PropTypes.string,
  userId: PropTypes.number,
};

IdToDisplayName.defaultProps = {
  adminId: 0,
  showUserName: false,
  systemUser: '',
  userId: 0,
};

export default IdToDisplayName;
