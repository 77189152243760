import SelectTransactionType from 'components/common/SelectTransactionType';
import { PERSON_TO_PERSON } from 'consts';
import {
  amountOperators,
  dateRangeOperators,
  equalOperators,
} from 'types/search_by';
import { decryptDestination, decryptFunding } from 'sdk/scheduledTransaction';
import MaskDestination from '../../config/MaskDestination';
import MaskFunding from '../../config/MaskFunding';
import ScheduledTransactionsExpandedRow from './ExpandedRow';
import TransactionIcon from '../../config/TransactionIcon';
import { getAutopayFilter } from '../../consts';

export { default as ActionsRenderer } from './ActionsRenderer';

export const columnMeta = [
  {
    xs: 1,
    id: 'transactionType',
    label: 'Type',
    sortId: 'transactionType',
    renderer: (type) => <TransactionIcon type={type} />,
  },
  {
    xs: 1,
    isDate: true,
    id: 'sendOn',
    label: 'Send Date',
    sortId: 'sendOn',
  },
  {
    xs: 3,
    id: 'fundingAccount',
    label: 'Funding Account',
    renderer: (_, item) => <MaskFunding decrypt={decryptFunding} item={item} />,
  },
  {
    xs: 2,
    id: 'destination',
    label: 'Destination',
    renderer: (_, item) =>
      item.transactionType !== PERSON_TO_PERSON ? (
        <MaskDestination decrypt={decryptDestination} item={item} />
      ) : null,
  },
  {
    xs: 1,
    id: 'statusDerived',
    label: 'Status',
  },
  {
    xs: 1,
    id: 'frequencyLabel',
    label: 'Frequency',
  },
  {
    xs: 1,
    id: 'endDateDerived',
    label: 'End Date',
  },
  {
    xs: 1,
    context: true,
    isCurrency: true,
    id: 'amount',
    label: 'Amount',
    sortId: 'amount',
  },
  {
    xs: 1,
    id: 'deliverOn',
    label: 'Deliver Date',
    sortId: 'deliverOn',
    isDate: true,
  },
];

export const createSearchFields = ({ fspId, payerId, showAutopayFilter }) => [
  {
    field: { name: 'Type', value: 'transactionType' },
    operators: [
      {
        name: 'is equal to',
        value: 'field',
        component: (props) => (
          <SelectTransactionType
            {...props}
            fspId={fspId}
            payerId={payerId}
            scheduled
          />
        ),
      },
    ],
  },
  {
    field: { name: 'Amount', value: 'amount' },
    operators: amountOperators,
  },
  {
    field: { name: 'Send On', value: 'sendOn' },
    operators: dateRangeOperators.map(
      ({ defaultValue, ...operator }) => operator
    ),
  },
  {
    field: { name: 'Confirmation #', value: 'confirmNumber' },
    operators: equalOperators,
  },
  ...getAutopayFilter(showAutopayFilter),
];

export const detailsRender = (item) => (
  <ScheduledTransactionsExpandedRow item={item} />
);
