import Grid from '@material-ui/core/Grid';
import MaskDestination from 'components/modules/service/PayerDetail/Transactions/config/MaskDestination';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import { decryptDestination, decryptFunding } from 'sdk/transactionHistory';
import { memo } from 'react';
import AutoPayOrFrequency from './AutoPayOrFrequency';
import CardInfo from './CardInfo';
import DeliveryType from './DeliveryType';
import DisburseState from './DisburseState';
import MaskFunding from '../../../config/MaskFunding';
import RDFIInfo from './RDFIInfo';
import Type from '../../Type';
import useLoad from './useLoad';
import { isP2P } from './consts';

const DetailsRender = ({ transaction }) => {
  useLoad(transaction);

  return (
    <Grid container>
      <Grid item xs={1}>
        <Type item={transaction} />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyFormControl
          label="Send Date"
          value={transaction.sendOnDerived}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          inputNode={
            transaction.fundingMask && (
              <MaskFunding decrypt={decryptFunding} icon item={transaction} />
            )
          }
          label="Funding Account"
          value={transaction.fundingMask}
        />
      </Grid>
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Status"
          multiline
          value={transaction.statusDerived}
        />
      </Grid>
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Deliver Date"
          value={transaction.deliverOnDerived}
        />
      </Grid>
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Confirmation #"
          value={transaction.confirmationNumber}
        />
      </Grid>
      <Grid item xs={1}>
        <AutoPayOrFrequency transaction={transaction} />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyFormControl label="History ID" value={transaction.id} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          inputNode={
            !isP2P(transaction.transactionType) && (
              <MaskDestination
                decrypt={decryptDestination}
                icon
                item={transaction}
              />
            )
          }
          label="Destination Account"
          value={
            transaction.destination
              ? transaction.destination
              : transaction.destinationMask
          }
        />
      </Grid>
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Funding Response"
          value={transaction.fundingResponse}
        />
      </Grid>
      <DisburseState transaction={transaction} />
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Delivery Method"
          value={transaction.deliveryMethodValue}
        />
      </Grid>
      <DeliveryType transaction={transaction} />
      <RDFIInfo transaction={transaction} />
      <CardInfo transaction={transaction} />
      <Grid item xs={1}>
        <ReadOnlyFormControl
          label={transaction.trackingLabelDerived}
          value={transaction.trackingNumberDerived}
        />
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default memo(DetailsRender);
