import Pagination from 'components/common/Pagination';
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AuditContext from './AuditContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 64,
    zIndex: 100,
  },
}));

const AuditPagination = () => {
  const classes = useStyles();
  const { action, actionParams, actionType, countSelector, selectorProps } =
    useContext(AuditContext);

  return (
    <div className={classes.root}>
      <Pagination
        action={action}
        actionParams={actionParams}
        actionType={actionType}
        component="div"
        labelRowsPerPage="Audit entries per page"
        name="audit"
        selector={countSelector}
        selectorProps={selectorProps}
      />
    </div>
  );
};

export default AuditPagination;
