import Permission from './Permission';

/**
 * Action Constants
 */
export const CREATE_ACCESS_PAYMENT_RESEARCH = 'create access TRR';

export const FULL_ACCESS_ADMIN = 'full access admin';
export const FULL_ACCESS_API_KEY_MANAGEMENT = 'full access api key management';
export const FULL_ACCESS_BILLER_MANAGEMENT = 'full access biller management';
export const FULL_ACCESS_CORS_DOMAIN_MANAGEMENT =
  'full access cors domain management';
export const FULL_ACCESS_FRAUD = 'full access fraud';
export const FULL_ACCESS_AI_FRAUD = 'full access ai fraud';
export const FULL_ACCESS_FSP_REPORT = 'full access fsp report';
export const FULL_ACCESS_IMPLEMENTATION = 'full access config';
export const FULL_ACCESS_PAYER_TRANSACT = 'full access payer transact';
export const FULL_ACCESS_PAYMENT_OPS = 'full access payment ops';
export const FULL_ACCESS_PAYMENT_RESEARCH = 'full access TRR';
export const FULL_ACCESS_PAYRAILZ_REPORT = 'full access payrailz report';
export const FULL_ACCESS_SERVICE = 'full access service';
export const FULL_ACCESS_USER_MGMT = 'full access user mgmt';

export const UPDATE_ACCESS_STATUS = 'update access status';
export const UPDATE_PAYMENT_STATUS = 'update payment status';
export const UPDATE_RECIPIENT_STATUS = 'update recipient status';
export const UPDATE_LIMITS = 'update limits';

export const VO_ACCESS_ADMIN = 'view only access admin';
export const VO_ACCESS_API_KEY_MANAGEMENT = 'view only api key management';
export const VO_ACCESS_BILLER_MANAGEMENT = 'view only access biller management';
export const VO_ACCESS_FRAUD = 'view only access fraud';
export const VO_ACCESS_AI_FRAUD = 'view only access ai fraud';
export const VO_ACCESS_FSP_REPORT = 'view only access fsp report';
export const VO_ACCESS_IMPLEMENTATION = 'view only access config';
export const VO_ACCESS_PAYMENT_OPS = 'view only access payment ops';
export const VO_ACCESS_SERVICE = 'view only access service';
export const VO_ACCESS_USER_MGMT = 'view only access user mgmt';

export const VIEW_AS_PAYER = 'view as payer';
export const VIEW_EBILL_PDF = 'view ebill pdf';

export const CreatePaymentResearchPermission = Permission(
  CREATE_ACCESS_PAYMENT_RESEARCH,
  FULL_ACCESS_PAYMENT_RESEARCH
);
export const EditPaymentResearchNotePermission = Permission(
  FULL_ACCESS_PAYMENT_RESEARCH
);
export const PaymentResearchPermission = Permission(
  CREATE_ACCESS_PAYMENT_RESEARCH,
  FULL_ACCESS_PAYMENT_RESEARCH,
  FULL_ACCESS_SERVICE,
  VO_ACCESS_SERVICE
);
export const ViewAdminUsersPermission = Permission(
  FULL_ACCESS_ADMIN,
  FULL_ACCESS_USER_MGMT,
  VO_ACCESS_ADMIN,
  VO_ACCESS_USER_MGMT
);
export const ViewPaymentResearchModulePermission = Permission(
  FULL_ACCESS_PAYMENT_RESEARCH
);
export const withBillMgmt = Permission(FULL_ACCESS_BILLER_MANAGEMENT);
export const withEbillPDF = Permission(VIEW_EBILL_PDF);
export const withFraud = Permission(FULL_ACCESS_FRAUD, VO_ACCESS_FRAUD);
export const withFullUserAccess = Permission(FULL_ACCESS_USER_MGMT);
export const withImplementationPermission = Permission(
  FULL_ACCESS_IMPLEMENTATION,
  VO_ACCESS_IMPLEMENTATION
);
export const withPaymentOps = Permission(FULL_ACCESS_PAYMENT_OPS);

export const withTransactAsPayerPermission = Permission(
  FULL_ACCESS_PAYER_TRANSACT
);
export const withUpdateLimits = Permission(UPDATE_LIMITS);
export const withUpdatePaymentStatus = Permission(UPDATE_PAYMENT_STATUS);
export const withViewAsPayer = Permission(VIEW_AS_PAYER);
export const withViewService = Permission(
  FULL_ACCESS_SERVICE,
  VO_ACCESS_SERVICE
);
export const withAdmin = Permission(
  FULL_ACCESS_ADMIN,
  FULL_ACCESS_FRAUD,
  FULL_ACCESS_USER_MGMT,
  VO_ACCESS_ADMIN,
  VO_ACCESS_FRAUD,
  VO_ACCESS_USER_MGMT
);
export const withEBillAdmin = Permission(
  FULL_ACCESS_BILLER_MANAGEMENT,
  FULL_ACCESS_PAYMENT_OPS
);
export const withApiKeyAdmin = Permission(
  FULL_ACCESS_API_KEY_MANAGEMENT,
  VO_ACCESS_API_KEY_MANAGEMENT
);
