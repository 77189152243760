import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { isNil } from 'ramda';
import IdToDisplayName from './IdToDisplayName';
import AuditDetailRowRecordStatus from './AuditDetailRowRecordStatus';

const AuditDetailRowRecordTitlePrefix = ({
  byAdminUserId,
  bySystemUser,
  byUserId,
  showStatus,
  status,
  title,
  ...props
}) => {
  const prefix = byAdminUserId ? 'admin' : byUserId ? 'user' : 'system';
  const showBoth = !isNil(byAdminUserId) && !isNil(byUserId);

  return (
    <Grid alignContent="center" alignItems="center" container wrap="nowrap">
      <Grid item>
        <Typography variant="body2">
          {title} by {prefix}
        </Typography>
      </Grid>
      <Grid item>
        <IdToDisplayName
          adminId={byAdminUserId}
          systemUser={bySystemUser}
          userId={byUserId}
          {...props}
        />
      </Grid>
      {showBoth && (
        <>
          <Grid item>
            <Typography variant="caption">on behalf of</Typography>
          </Grid>
          <Grid item>
            <IdToDisplayName adminId={null} userId={byUserId} {...props} />
          </Grid>
        </>
      )}
      <Grid item>
        <AuditDetailRowRecordStatus status={status} />
      </Grid>
    </Grid>
  );
};

AuditDetailRowRecordTitlePrefix.propTypes = {
  byAdminUserId: PropTypes.number,
  bySystemUser: PropTypes.string,
  byUserId: PropTypes.number,
  showStatus: PropTypes.bool,
  status: PropTypes.string,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

AuditDetailRowRecordTitlePrefix.defaultProps = {
  byAdminUserId: 0,
  bySystemUser: '',
  byUserId: 0,
  showStatus: true,
  status: null,
};

export default AuditDetailRowRecordTitlePrefix;
