import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { derivedSelectorId } from 'selectors/transactionHistory';
import { load } from 'model/transactionHistoryAudit';
import {
  selectorCount,
  selectorGroupedByDays,
} from 'selectors/transactionHistoryAudit';
import AuditDetailRowRecord from 'components/modules/service/Audit/config/transactionHistory/AuditDetailRowRecord';
import AuditDrawerAppBarTitle from 'components/modules/service/Audit/config/transaction/AuditDrawerAppBarTitle';
import Audit from 'components/modules/service/Audit';

const HistoryAudit = ({ buttonLabel, id }) => {
  const item = useSelector((state) => derivedSelectorId(state, { id }));

  return (
    <Audit
      action={load}
      buttonLabel={buttonLabel}
      countSelector={selectorCount}
      item={item}
      Row={AuditDetailRowRecord}
      selectorGroupedByDays={selectorGroupedByDays}
      selectorId={derivedSelectorId}
      Title={AuditDrawerAppBarTitle}
    />
  );
};

HistoryAudit.propTypes = {
  buttonLabel: PropTypes.string,
  id: PropTypes.number.isRequired,
};

HistoryAudit.defaultProps = {
  buttonLabel: '',
};

export default HistoryAudit;
