import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  transactAsPayer,
  TRANSACT_AS_PAYER,
  selector,
} from 'reducers/transactAs';
import { useFSPId } from 'hooks';
import { profileSelector } from 'selectors/payer';
import { withTransactAsPayerPermission } from 'components/common/permissions';
import ConfirmDialog from './ConfirmDialog';
import PayerOrPayerUserButton from '../PayerOrPayerUserButton';

const TransactAsButton = ({
  payerId,
  recipientId,
  payeeId,
  transactionId,
  transactionType,
}) => {
  const payer = useSelector((state) => profileSelector(state, { id: payerId }));
  const dispatch = useDispatch();
  const fspId = useFSPId();
  const [confirmData, setConfirmData] = useState();

  const handlePayerUserClick = (event, payerUser = {}) => {
    const data = {
      fspId,
      payerId,
      userId: payerUser.userId,
      payerUser,
      recipientId,
      payeeId,
      transactionId,
      transactionType,
      sso: 'on',
    };
    setConfirmData(transactAsPayer(data));
  };

  const handleCancel = useCallback(
    () => setConfirmData(undefined),
    [setConfirmData]
  );
  const handleConfirm = useCallback(
    () => dispatch(confirmData),
    [dispatch, confirmData]
  );
  return (
    <>
      <ConfirmDialog
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        open={Boolean(confirmData)}
        payerName={payer.name}
      />
      <PayerOrPayerUserButton
        onPayerClick={handlePayerUserClick}
        onPayerUserClick={handlePayerUserClick}
        payerAction={TRANSACT_AS_PAYER}
        payerButtonText="Transact As Payer"
        payerId={payerId}
        payerType={payer.payerType}
        payerUserAction={TRANSACT_AS_PAYER}
        payerUserButtonText="Transact As User"
        selector={selector}
        transactAs
      />
    </>
  );
};

TransactAsButton.propTypes = {
  payerId: PropTypes.number.isRequired,
  recipientId: PropTypes.number,
  payeeId: PropTypes.number,
  transactionId: PropTypes.number,
  transactionType: PropTypes.string,
};

TransactAsButton.defaultProps = {
  recipientId: null,
  payeeId: null,
  transactionId: null,
  transactionType: null,
};

export default withTransactAsPayerPermission(TransactAsButton);
