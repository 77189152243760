import LoadingSkeleton from 'react-loading-skeleton';
import moment from 'moment';
import { cond, equals, always, T } from 'ramda';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { date, dateAndTime } from '../util';

const Skeleton = <LoadingSkeleton width={15} />;

const CREATED = 'created';
const PRESENTED = 'presented';
const COMPLETED = 'completed';
const DISMISSED = 'dismissed';
const EXPIRED = 'expired';

const getStatus = ({ status, expireAt }) =>
  status === CREATED
    ? moment().diff(moment(expireAt)) > 0
      ? EXPIRED
      : ''
    : status;

const getActionAt = ({ status, presentedAt, completedAt, dismissedAt }) =>
  cond([
    [equals(PRESENTED), always(dateAndTime(presentedAt))],
    [equals(COMPLETED), always(dateAndTime(completedAt))],
    [equals(DISMISSED), always(dateAndTime(dismissedAt))],
    [T, always('')],
  ])(status);

const selectorModel = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { payerId }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).actionableAlerts.toModelArray()
      : []
);

const selector = createReselectSelector(selectorModel, (items) =>
  items.map((item) => {
    const {
      completedAt,
      createdAt,
      dismissedAt,
      expireAt,
      presentedAt,
      status,
    } = item;

    item.set(
      'actionAt',
      getActionAt({ status, presentedAt, completedAt, dismissedAt })
    );
    item.set('createdAtDerived', dateAndTime(createdAt));
    item.set('expireDate', date(expireAt));
    item.set('statusDerived', getStatus({ status, expireAt }));

    return item.ref;
  })
);

export const unreadCountSelector = createSelector(
  orm,
  (state, props) => props,
  ({ ActionableAlert, Payer }, { payerId }) => {
    let retVal = Skeleton;

    if (ActionableAlert.idExists(0) && Payer.idExists(payerId)) {
      const emptyProxy = ActionableAlert.withId(0);
      const items = Payer.withId(payerId).actionableAlerts;

      if (!emptyProxy.isEmpty) {
        retVal = items
          .filter(
            ({ status, expireAt }) => getStatus({ status, expireAt }) === ''
          )
          .count();
      }
    }

    return retVal;
  }
);

export default selector;
