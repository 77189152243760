import { client, formatQueryParams } from 'util/sdk';

export const cancel = ({ fspId, ids, reason }, auth) =>
  client
    .fsps(fspId)
    .cancelFspFraudTransaction(
      { fspschedtran: ids, cancelReason: reason },
      { adminUserId: auth.id }
    );

export const load = ({ fspId, ...params }) => {
  return client.fsps(fspId).fspFraudTransactions.query(
    formatQueryParams({
      ...params,
      // Strict Rule, resultReason parameter must follow these rules in order to work:
      // 1. Just one item, do not send quotes, example: resultReason: cancel_ontrac
      // 2. More than one item, send json array format, example: resultReason: ["cancel_ontrac","cancel_batch"]
      resultReason:
        typeof params.resultReason === 'string'
          ? params.resultReason
          : JSON.stringify(params.resultReason),
    })
  );
};
