import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class Channel extends Model {
  static get modelName() {
    return 'Channel';
  }

  static reducer({ type, data }, Channel) {
    switch (type) {
      case ADD.SUCCESS:
      case LOAD_ID.SUCCESS:
        Channel.upsert(data.response);
        break;

      case CHECK.ACTION: {
        const channel = Channel.withId(data.id);
        if (channel) {
          channel.selected = !channel.selected;

          channel.update(channel);
        }
        break;
      }

      case CHECK_ALL.ACTION:
        Channel.update({ selected: data.selected });
        break;

      case REMOVE.SUCCESS:
        Channel.withId(data.id).delete();
        break;

      case LOAD.SUCCESS:
      case THROTTLED_LOAD.SUCCESS:
        if (data?.response) {
          data.response.forEach((r) => Channel.upsert(r));
        }
        break;

      case UPDATE.SUCCESS:
        Channel.update(data.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      description: attr(),
      href: attr(),
      id: attr(),
      name: attr(),
      offset: attr(),
      updatedAt: attr(),
    };
  }
}

const name = Channel.modelName;

export const ADD = createAddConst(name);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const LOAD_ID = createRequestConst(`${name}_id`);
export const THROTTLED_LOAD = createLoadConst(`${name}_throttled`);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const loadId = createAction(LOAD_ID);
export const throttledLoad = createAction(THROTTLED_LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
