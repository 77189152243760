import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  CLEAR_FAILED,
  DELIVER_CHECK_EXPEDITE,
  DELIVER_CHECK_STD,
  DELIVER_ELEC_STD,
  NA_VALUE,
  PERSON_TO_PERSON,
  TRANSACTION_TYPE_BILL_PAY,
  USD,
  USPS_RETURNED,
} from 'consts';
import {
  findByCode,
  getType,
} from 'components/modules/service/PayerDetail/Transactions/config/transactions';
import {
  isFIDirect as isFIDirectUtil,
  isRefund,
} from 'components/modules/service/PayerDetail/Transactions/History/config/DetailsRender/consts';
import moment from 'moment/moment';
import { formatCurrency, date } from '../../util';
import {
  getDeliveryMethod,
  getP2PDisbursementType,
  getFrequency,
} from './transaction';

export const getTypeValue = getType;
export const isFIDirect = isFIDirectUtil;

const returnedDescription = 'Transaction was processed and then returned';

/**
 * Reference:
 * https://payrailz.atlassian.net/wiki/spaces/PP/pages/466812939/Transaction+Status+to+Ontrac+UI+Mapping
 * https://www.lucidchart.com/documents/edit/5e4da723-1532-4d45-99d4-6c95d54619a2
 */
export const statusCodes = [
  /**
   * Transaction End States - visible in Status filtering in Transaction History
   */
  {
    code: 'auth_denied',
    description:
      'Host denied funding (ie. insufficient funds, invalid account, account closed, etc)',
    value: 'Authorization Denied',
  },
  {
    code: 'auth_failed',
    description: 'Host (ie. core) unable to process',
    value: 'Authorization Failed',
  },
  {
    code: 'cancelled',
    description:
      'An OnHold schedule transaction that was cancelled by the consumer',
    value: 'Cancelled',
  },
  {
    code: 'delivered',
    description:
      'Final state for a check payment from USPS confirmation of delivery',
    value: 'Delivered',
  },
  {
    code: 'expired',
    description:
      'An OnHold schedule transaction that expired after X days due to inaction by the consumer',
    value: 'Expired',
  },
  {
    code: 'pay_failed',
    description: 'A returned transaction after refund is completed',
    value: 'Pay Failed',
  },
  {
    code: 'processed',
    description: 'Disbursement batch processing completed by Payrailz',
    value: 'Processed',
  },
  /**
   * Initiation  States
   */
  {
    code: 'schedule',
    description: 'Transaction scheduled and ready for processing on Send Date',
    value: 'Scheduled',
  },
  {
    code: 'on_hold',
    description:
      'Conversion transaction that requires update by the consumer in order to process',
    value: 'On Hold',
  },
  /**
   * Funding States
   */
  {
    code: 'ready_to_xfer',
    description: 'A2A transaction queued up and ready for exec',
    value: 'Ready to Transfer',
  },
  {
    code: 'ready_to_auth',
    description: 'Bill Pay, P2P, and Checks queued up and ready for exec',
    value: 'Ready to Authorize',
  },
  {
    code: 'auth_sent',
    description: 'Bill Pay, P2P, and Checks sent for funding',
    value: 'Authorization Sent',
  },
  {
    code: 'authorized',
    description: 'Bill Pay, P2P, and Checks approved for disbursement',
    value: 'Authorized',
  },
  {
    code: 'cleared',
    description: 'Check has cleared',
    value: 'Cleared',
  },
  {
    code: CLEAR_FAILED,
    description: 'Clear Failed',
    value: 'Clear Failed',
  },
  //
  // Not in for MVP - Funding States
  //
  // {
  //   code: 'auth_indoubt',
  //   description: 'Real time support; N/A for MVP',
  //   value: 'N/A',
  // },
  // {
  //   code: 'auth_reversed',
  //   description: 'Real time support; N/A for MVP',
  //   value: 'N/A',
  // },
  /**
   * Disbursement States
   */
  {
    code: 'sent',
    description:
      'Disbursed to transaction destinations (ie. billers, contacts, FSPs, etc)',
    value: 'Sent',
  },
  {
    code: 'ready_to_send',
    description:
      'Bill Pay, P2P, and Checks queued up and ready for disbursement',
    value: 'Ready to Send',
  },
  //
  // Not in for MVP - Disbursement States
  //
  // {
  //   code: 'send_indoubt',
  //   value: 'N/A',
  //   description: 'Real time support; N/A for MVP',
  // },
  /**
   * Return & Refund States
   */
  {
    code: 'rejected',
    description: 'Transaction rejected by',
    value: 'Rejected',
  },
  {
    code: 'returned',
    description: returnedDescription,
    value: 'Returned',
  },
  {
    code: USPS_RETURNED,
    description: returnedDescription,
    value: 'USPS Returned',
  },
  {
    code: 'ready_to_refund',
    description: 'Rejected or returned transactions that require a refund',
    value: 'Ready to Refund',
  },
  {
    code: 'refund_sent',
    description: 'Refund sent (credit back to consumer)',
    value: 'Refund Sent',
  },
  {
    code: 'refund_failed',
    description: 'Refund failed via batch credit request',
    value: 'Refund Failed',
  },
  {
    code: 'manual_refund',
    description:
      "Manual refund (call the FSP to credit the consumer's account)",
    value: 'Returned',
  },
  {
    code: 'refunded',
    description: 'Consumer credit completed',
    value: 'Refunded',
  },
  {
    code: 'onholdRecipient',
    description:
      'Notification is pending action by Contact to complete payment',
    value: 'Pending Contact',
  },
  {
    code: 'stopped',
    description: 'Stop payment has been processed',
    value: 'Stopped',
  },
];

/**
 * Reference:
 * See https://payrailz.atlassian.net/browse/ON-425
 * Wait 2 business days for PR tickets
 */
const businessDaysSinceDeliverOn = (deliverOn) =>
  moment().businessDiff(moment(deliverOn));

/**
 * Reference:
 * https://payrailz.atlassian.net/browse/UI-314
 */
const responseCodes = [
  { code: 'R00', value: 'Success No Exception' },
  { code: 'R01', value: 'Failed Insufficient Funds' },
  { code: 'R02', value: 'Failed Account Closed' },
  { code: 'R04', value: 'Failed Invalid Account Number' },
  { code: 'R16', value: 'Failed Account Frozen' },
  { code: 'R20', value: 'Failed Non-Transaction Account' },
  { code: 'R20', value: 'Failed Non-Transaction Account' },
  { code: 'R98', value: 'Failed Other' },
  { code: 'R99', value: 'Success Exception' },
  { code: '', value: NA_VALUE },
];

const getTrackingInfo = (item) => {
  let label = 'Trace Number';
  let number = 'N/A';

  if (item.transactionType === TRANSACTION_TYPE_BILL_PAY) {
    if (item.deliver === DELIVER_ELEC_STD) {
      label = 'RPPS Trace Number';
      if (item.rppsTraceNumber) {
        number = item.rppsTraceNumber;
      }
    } else if (item.deliver === DELIVER_CHECK_STD) {
      // Updated 02/-2/2020 - // https://payrailz.atlassian.net/browse/ON-575
      label = 'USPS IMB';
      if (item.intelligentMailBarcode) {
        number = item.intelligentMailBarcode;
      }
    } else if (item.deliver === DELIVER_CHECK_EXPEDITE) {
      // Updated 02/-2/2020 - // https://payrailz.atlassian.net/browse/ON-575
      label = 'FedEx Tracking Number';
      if (item.shippingTrackingNumber) {
        number = item.shippingTrackingNumber;
      }
    }
  } else if (item.transactionType === PERSON_TO_PERSON) {
    if (item.deliver === DELIVER_ELEC_STD) {
      label = 'P2P Trace Number';
      if (item.p2pTraceNumber) {
        number = item.p2pTraceNumber;
      }
    }
  } else if (
    (item.transactionType === ACCOUNT_TO_ACCOUNT_INBOUND ||
      item.transactionType === ACCOUNT_TO_ACCOUNT_OUTBOUND ||
      item.transactionType === ACCOUNT_TO_ACCOUNT_NEW) &&
    item.deliver === DELIVER_ELEC_STD
  ) {
    label = 'A2A Trace Number';

    if (item.a2aTraceNumber) {
      number = item.a2aTraceNumber;
    }
  }

  return { label, number };
};

const getResponseCodeDisplayValue = (code) =>
  findByCode(responseCodes, code).value;

const getFundingResponse = (item) => {
  let fundingResponse = NA_VALUE;

  if (item.hostRespCode) {
    fundingResponse = getResponseCodeDisplayValue(item.hostRespCode);
  }

  if (item.respCodeIdStr && item.respCodeIdStr.trim().length) {
    if (item.hostRespCode && item.hostRespCode.trim().length) {
      fundingResponse = `${fundingResponse} (${item.respCodeIdStr.trim()})`;
    } else {
      fundingResponse = item.respCodeIdStr.trim();
    }
  }

  return fundingResponse;
};

const getNetworkResponse = (item) => {
  const { networkResponseCode, networkResponseCodeStr } = item;
  if (networkResponseCode || networkResponseCodeStr) {
    if (networkResponseCode && networkResponseCodeStr) {
      return `${networkResponseCodeStr} (${networkResponseCode})`;
    }
    return networkResponseCodeStr || networkResponseCode;
  }
  return null;
};

const getSuffixes = (item) => {
  const suffix = item.dda ? item.dda.accountSuffix : item.ddaAccountSuffix;
  let destinationAccountSuffix = null;
  let fundingAccountSuffix = null;

  switch (item.transactionType) {
    case ACCOUNT_TO_ACCOUNT_INBOUND:
    case ACCOUNT_TO_ACCOUNT_NEW:
      destinationAccountSuffix = suffix;
      break;

    case ACCOUNT_TO_ACCOUNT_OUTBOUND:
    case TRANSACTION_TYPE_BILL_PAY:
    case PERSON_TO_PERSON:
      fundingAccountSuffix = suffix;
      break;

    default:
      break;
  }

  return {
    destinationAccountSuffix,
    fundingAccountSuffix,
  };
};

export const getStatusCodeDisplayValue = (code) => {
  if (code) {
    return findByCode(statusCodes, code)?.value;
  }

  return code;
};

export const getStatusCodeDisplayDescription = (code) => {
  const { description } = findByCode(statusCodes, code);
  return description || code;
};

export const derived = (item) => {
  const trackingInfo = getTrackingInfo(item);
  const networkResponse = getNetworkResponse(item);
  let statusDerived = getStatusCodeDisplayValue(item.status);
  // Added 10/10/2022 https://payrailz.atlassian.net/browse/ON-1652
  // The 'networkResponse' part should not be added when it's refunded transaction
  // because it's being added to disburseStateDerived field
  if (networkResponse && !isRefund(item)) {
    statusDerived += `\r\n${networkResponse}`;
  }

  return {
    ...item,
    amountDerived: `${formatCurrency(item.amount, { code: USD })}`,
    businessDaysSinceDeliverOnDerived: businessDaysSinceDeliverOn(
      item.deliverOn
    ),
    deliveryMethodValue: getDeliveryMethod(item),
    deliverOnDerived: date(item.deliverOn),
    disburseTypeDerived: getP2PDisbursementType(item?.disburseType),
    frequencyLabel: getFrequency(item?.frequency),
    fundingResponse: getFundingResponse(item),
    isEmpty: false,
    isFIDirectDerived: isFIDirect(item?.deliver, item?.disburseType),
    sendOnDerived: date(item.sendOn),
    statusDerived,
    subtitle: `${date(item.sendOn)} - ${formatCurrency(item.amount)}`,
    title: getTypeValue(item.transactionType),
    trackingLabelDerived: trackingInfo.label,
    trackingNumberDerived: trackingInfo.number,
    transactionTypeDerived: getTypeValue(item.transactionType),
    disburseStateDerived: networkResponse,
    ...getSuffixes(item),
  };
};
