import { client, formatQueryParams } from 'util/sdk';
import { FSP_CONFIG_CODE, SSO_OVERWRITE } from 'consts';

const returnModel = { name: FSP_CONFIG_CODE };

const formatFspConfig = (fspConfig) => {
  const overwrite = fspConfig.ssoConfig?.[SSO_OVERWRITE];
  if (overwrite && typeof overwrite === 'object') {
    fspConfig.ssoConfig[SSO_OVERWRITE] = JSON.stringify(overwrite);
  }

  return fspConfig;
};

export const create = ({ fspId, ...params }, auth) => {
  return client
    .fsps(fspId)
    .fspConfigs.create(formatFspConfig(params), { adminUserId: auth.id });
};

export const load = ({ fspId, ...params }) => {
  return client.fsps(fspId).fspConfigs.query(formatQueryParams(params));
};

export const loadById = ({ fspId, id }) => {
  return client.fsps(fspId).fspConfigs(id).get();
};

export const update = ({ fspId, id, ...data }, auth) =>
  client.executeRequest('POST', `fsps/${fspId}/fspconfigs/${id}`, {
    data: formatFspConfig(data),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
