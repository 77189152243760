import SelectTransactionType from 'components/common/SelectTransactionType';
import { decryptDestination, decryptFunding } from 'sdk/transactionHistory';
import {
  AMOUNT,
  AMOUNT_CODE,
  PERSON_TO_PERSON,
  TRANSACTION_TYPE,
} from 'consts';
import { FundingMask } from 'components/common/fields';
import {
  amountOperators,
  dateRangeOperators,
  equalOperators,
  stringOperatorsSdk,
} from 'types/search_by';
import DetailsRender from './DetailsRender';
import MaskDestination from '../../config/MaskDestination';
import MaskFunding from '../../config/MaskFunding';
import StatusSearchSelect from './StatusSearchSelect';
import TransactionIcon from '../../config/TransactionIcon';
import { getAutopayFilter } from '../../consts';

export const columnMeta = [
  {
    xs: 1,
    id: TRANSACTION_TYPE,
    label: 'Type',
    sortId: TRANSACTION_TYPE,
    renderer: (type, { disburseType, p2pDirectOffOn }) => (
      <TransactionIcon
        disburseType={disburseType}
        p2pDirectOffOn={p2pDirectOffOn}
        type={type}
      />
    ),
  },
  {
    xs: 2,
    isDate: true,
    id: 'sendOn',
    label: 'Send Date',
    sortId: 'sendOn',
  },
  {
    xs: 3,
    id: 'fundingAccount',
    label: 'Funding Account',
    sortId: 'fundingAccount',
    renderer: (_, item) => <MaskFunding decrypt={decryptFunding} item={item} />,
  },
  {
    xs: 3,
    id: 'destination',
    label: 'Destination',
    sortId: 'destination',
    renderer: (_, item) =>
      item.transactionType !== PERSON_TO_PERSON ? (
        <MaskDestination decrypt={decryptDestination} item={item} />
      ) : null,
  },
  {
    xs: 1,
    context: true,
    isCurrency: true,
    id: AMOUNT_CODE,
    label: AMOUNT,
    sortId: AMOUNT_CODE,
  },
  {
    xs: 2,
    id: 'statusDerived',
    label: 'Status',
    sortId: 'status',
  },
];

export const INCORRECT_ACCOUNT_NUMBER = 'C01';
export const INCORRECT_ROUTING_NUMBER = 'C02';
export const INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER = 'C03';
export const INCORRECT_ACCOUNT_TYPE = 'C05';
export const INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE = 'C06';
export const INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE = 'C07';

export const nocCodes = [
  { name: 'Incorrect DFI account number', value: INCORRECT_ACCOUNT_NUMBER },
  { name: 'Incorrect routing number', value: INCORRECT_ROUTING_NUMBER },
  {
    name: 'Incorrect routing number and DFI account number',
    value: INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER,
  },
  { name: 'Incorrect transaction code', value: INCORRECT_ACCOUNT_TYPE },
  {
    name: 'Incorrect DFI account number and incorrect transaction code',
    value: INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE,
  },
  {
    name: 'Incorrect Routing Number, Incorrect DFI Account Number and Incorrect Transaction Code',
    value: INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
  },
];

export const createSearchFields = ({ fspId, payerId, showAutopayFilter }) => [
  {
    field: { name: 'Type', value: TRANSACTION_TYPE },
    operators: [
      {
        name: 'is equal to',
        value: 'field',
        component: (props) => (
          <SelectTransactionType
            {...props}
            fspId={fspId}
            payerId={payerId}
            scheduled={false}
          />
        ),
      },
    ],
  },
  {
    field: { name: AMOUNT, value: AMOUNT_CODE },
    operators: amountOperators,
  },
  {
    field: { name: 'Sent Date', value: 'sendOn' },
    operators: dateRangeOperators,
  },
  {
    field: { name: 'Funding Account (last 4)', value: 'fundingMask' },
    operators: [
      {
        name: 'is equal to',
        value: 'field',
        component: FundingMask,
      },
    ],
  },
  {
    field: { name: 'Status', value: 'status' },
    operators: [
      {
        name: 'is equal to',
        value: 'field',
        component: StatusSearchSelect,
      },
    ],
  },
  {
    field: { name: 'Confirmation #', value: 'confirmationNumber' },
    operators: equalOperators,
  },
  {
    field: { name: 'Payee Name (Biller)', value: 'payeeName' },
    operators: stringOperatorsSdk,
  },
  {
    field: {
      name: 'Intelligent Mail Barcode',
      value: 'intelligentMailBarcode',
    },
    operators: equalOperators,
  },
  ...getAutopayFilter(showAutopayFilter),
];

export const detailsRender = (transaction) => (
  <DetailsRender transaction={transaction} />
);
