import {
  isEqual,
  DIRECT_CONNECT,
  EMAIL,
  IMMEDIATE,
  NA_VALUE,
  NEXT_DAY,
  ON,
  ON_HOLD_VALIDATE,
  TEXT_MESSAGE,
  VENMO,
  VENMO_SENT_BY,
} from 'consts';
import { dateAndTime, getPhoneForDisplay, isOn } from 'util/index';
import { getP2PDisbursementType } from './transaction';

export const DATA_KEY = 'recipients';
export const DATA_KEY_DIRECT = 'recipientsDirect';

const getDeliveryMethod = ({
  deliverMethod,
  p2pDirectOffOn,
  recipientStatus,
}) => {
  // Contacts - updates for Venmo
  // https://payrailz.atlassian.net/browse/ON-1132
  if (isOn(p2pDirectOffOn)) {
    return VENMO;
  }

  // Contacts - updates for P2P support
  // https://payrailz.atlassian.net/browse/ON-896
  // If the recipients status is onHoldValidate display N/A otherwise if the deliver_method
  // is dc display Immediate else display Next Day.
  if (isEqual(recipientStatus, ON_HOLD_VALIDATE)) {
    return NA_VALUE;
  }

  if (isEqual(deliverMethod, DIRECT_CONNECT)) {
    return IMMEDIATE;
  }

  return NEXT_DAY;
};

const getNotificationMethod = ({
  notifyEmailOffOn,
  notifyMobilePhoneOffOn,
  p2pDirectOffOn,
}) => {
  // Contacts - updates for Venmo
  // https://payrailz.atlassian.net/browse/ON-1132
  if (isOn(p2pDirectOffOn)) {
    return VENMO_SENT_BY;
  }

  return notifyEmailOffOn === ON
    ? EMAIL
    : notifyMobilePhoneOffOn === ON
    ? TEXT_MESSAGE
    : undefined;
};

export const getName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

export const addDerived = (r) => {
  r.deliveryMethodDerived = getDeliveryMethod(r);
  r.disburseTypeDerived = getP2PDisbursementType(r.disburseType);
  r.mobileDisplay = getPhoneForDisplay(r.mobilePhone);
  r.name = getName(r);
  r.nickName = (r.nickName && r.nickName.trim()) || NA_VALUE;
  r.notificationMethodDerived = getNotificationMethod(r);
  r.previousStatus = r.previousRecipientStatus;
  r.recipientStatusAtDerived = dateAndTime(r.recipientStatusAt);
  r.status = r.recipientStatus;

  return r;
};
