import PropTypes from 'prop-types';
import { useCallback } from 'react';
import NoteEditor from 'components/modules/paymentResearch/Note/NoteEditor';

import { Grid, Modal, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  modal: {
    margin: `${theme.spacing(4)}px auto`,
    width: '80%',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
});

const StatusChangeModal = ({ open, setOpen, classes, item, onSubmit }) => {
  const handleClose = useCallback(
    (event) => {
      event.stopPropagation();
      setOpen(false);
    },
    [setOpen]
  );

  const handleSubmit = useCallback(
    (event) => {
      event.stopPropagation();
      onSubmit();
      handleClose(event);
    },
    [handleClose, onSubmit]
  );
  const handleClick = useCallback((event) => event.stopPropagation(), []);
  const handleRef = useCallback((ref) => ref && ref.focus(), []);

  return (
    <Modal onClose={handleClose} open={open}>
      <Grid
        className={classes.modal}
        container
        direction="column"
        onClick={handleClick}
      >
        <Typography variant="subtitle1">Status: Solved</Typography>
        <NoteEditor
          canAddPrivateNote={false}
          editorRef={handleRef}
          fspId={item.fspId}
          onCancel={handleClose}
          onSubmit={handleSubmit}
          placeholder="A comment is required"
          submitButtonText="Done"
          trrId={item.id}
        />
      </Grid>
    </Modal>
  );
};

StatusChangeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(StatusChangeModal);
