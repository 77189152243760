import { DEFAULT_MODEL_REF, FRAUD_OFF_ON } from 'consts';
import { isOn } from 'util/index';

const findKey = (fspFraudConfig, key = '') => {
  return fspFraudConfig && Object.keys(fspFraudConfig).length >= 1
    ? isOn(fspFraudConfig[key])
    : undefined;
};

export const combinerSelectorObject = (r) => r ?? DEFAULT_MODEL_REF;
export const fraudEnabled = (r) => findKey(r, FRAUD_OFF_ON);
