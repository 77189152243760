import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';

const expiredSubheader = 'Please sign in again';
const expiredTitle = 'Your session has expired';
const unauthorizedSubheader = 'Check your VPN or contact an Administrator';
const unauthorizedTitle = 'Access Restricted';
const subheaderTypographyProps = {
  color: 'textSecondary',
  variant: 'subtitle1',
};
const titleTypographyProps = {
  color: 'textPrimary',
  variant: 'h5',
};

const PasswordVerifyHeader = ({ tokenExpired, unauthorized }) => {
  const subheader = tokenExpired
    ? expiredSubheader
    : unauthorized
    ? unauthorizedSubheader
    : undefined;
  const title = tokenExpired
    ? expiredTitle
    : unauthorized
    ? unauthorizedTitle
    : undefined;

  return tokenExpired || unauthorized ? (
    <CardHeader
      subheader={subheader}
      subheaderTypographyProps={subheaderTypographyProps}
      title={title}
      titleTypographyProps={titleTypographyProps}
    />
  ) : null;
};

PasswordVerifyHeader.propTypes = {
  tokenExpired: PropTypes.bool.isRequired,
  unauthorized: PropTypes.bool,
};

PasswordVerifyHeader.defaultProps = {
  unauthorized: false,
};

export default PasswordVerifyHeader;
