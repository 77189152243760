import { attr, Model } from 'redux-orm';
import { addDerived } from './util/fspPayerType';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class FSPPayerType extends Model {
  static get modelName() {
    return 'FSPPayerType';
  }

  static reducer({ type, data }, FSPPayerType) {
    switch (type) {
      case CHECK.ACTION: {
        const payerType = FSPPayerType.withId(data.id);
        if (payerType) {
          payerType.selected = !payerType.selected;

          payerType.update(payerType);
        }
        break;
      }

      case CHECK_ALL.ACTION:
        FSPPayerType.update({ selected: data.selected });
        break;

      case LOAD.SUCCESS:
        FSPPayerType.delete();
        data.response.forEach((r) => FSPPayerType.upsert(addDerived(r)));
        break;

      case CREATE.SUCCESS:
        FSPPayerType.create(addDerived(data.response));
        break;

      case REMOVE.SUCCESS:
        FSPPayerType.filter(({ selected }) => selected).delete();
        break;

      case UPDATE.SUCCESS:
        FSPPayerType.withId(data.response.id).update(addDerived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      a2aGoodFundsOffOn: attr(),
      a2aInOffOn: attr(),
      a2aOutOffOn: attr(),
      bpEbillOffOn: attr(),
      bpExpediteFeeAmount: attr(),
      bpExpediteOffOn: attr(),
      bpInvoiceOffOn: attr(),
      bpOffOn: attr(),
      bpxOffOn: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      fiDirectOffOn: attr(),
      fsp: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insightOffOn: attr(),
      internalTransferOffOn: attr(),
      offset: attr(),
      otpHighRiskBillPayAmount: attr(),
      otpHighRiskP2PAmount: attr(),
      otpHighRiskTransferFromAmount: attr(),
      otpHighRiskTransferToAmount: attr(),
      otpOffOn: attr(),
      p2pDirectOffOn: attr(),
      p2pOffOn: attr(),
      p2pRealtimeFeeAmount: attr(),
      p2pRealtimeOffOn: attr(),
      ssoA2AOffOn: attr(),
      ssoBPOffOn: attr(),
      ssoP2POffOn: attr(),
      type: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
    };
  }
}

const name = FSPPayerType.name;

export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const CREATE = createAddConst(name);
export const LOAD = createRequestConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const update = createAction(UPDATE);
export const remove = createAction(REMOVE);
