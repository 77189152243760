import * as yup from 'yup';
import {
  CHANNEL_CODE,
  CHANNEL_LABEL,
  DISTRIBUTOR_CODE,
  DISTRIBUTOR_LABEL,
  FSP_CODE,
  FSP_LABEL,
  OAUTH2,
} from 'consts';

export const pageSettingsName = 'apiKey';

export const IPV4S = 'ipv4s';
export const ALL_IPS = 'allAPIs';
export const SCOPES = 'scopes';
export const ROLES = 'roles';
export const CIDR_ALLOWED_RANGE = {
  MIN: 17,
  MAX_WITH_WARNING: 24,
  MAX: 32,
};
export const REGEX_API_V4_CIDR =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(%|(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([123][0-9]?)))$/;

const createNewObject = { id: null, name: 'Create New API Key...' };
const optional =
  'Creating a FSP starts with defining the channel that will host it, you must first select an existing channel or you can create a new one';
const stepLabel = 'API Key';
const stepPosition = 1;

const getLabel = (label) => {
  const { description, id, name } = label || {};
  const showDescription = description && description !== name;

  return id > 1
    ? `${name}${showDescription ? ` - ${description}` : ''} (${id})`
    : name || '';
};

export const initialValues = {
  owner: '',
  name: '',
  clientKey: '',
  clientSecret: '',
  scopes: [],
  ipv4s: [],
  cidrs: [],
  allAPIs: [],
  roles: [],
  type: OAUTH2,
  accessExpSec: 300,
  refreshExpSec: 300,
};
export const name = 'channelId';
export const step = {
  createNewObject,
  getLabel,
  name,
  optional,
  stepLabel,
  stepPosition,
};

export const validationSchema = yup.object({
  name: yup.string().required('A name is required'),
  owner: yup.string().required('An owner is required'),
  clientKey: yup.string().required('A client key is required'),
  clientSecret: yup.string().required('A client secret is required'),
  scopes: yup.array().ensure().min(1, 'Minimum of 1 Scope is required'),
  allAPIs: yup.array().ensure().min(1, 'Minimum of 1 IP is required'),
  roles: yup.array().ensure().min(1, 'Minimum of 1 Role is required'),
  type: yup.string().required('A type is required'),
  accessExpSec: yup
    .number()
    .nullable()
    .when('type', {
      is: OAUTH2,
      then: yup
        .number()
        .typeError('Access expiration must be a number')
        .required('Access expiration is required'),
    }),
  refreshExpSec: yup
    .number()
    .nullable()
    .when('type', {
      is: OAUTH2,
      then: yup
        .number()
        .typeError('Refresh expiration must be a number')
        .required('Refresh expiration is required'),
    }),
});

const getButtonDisabled = (formikContext) => {
  const { dirty, isSubmitting } = formikContext;

  return isSubmitting || !dirty;
};

export const getValue = (formikContext) => {
  const {
    dirty,
    handleSubmit,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
  } = formikContext;

  return {
    dirty,
    disabled: getButtonDisabled(formikContext),
    handleSubmit,
    isValid,
    isSubmitting,
    resetForm,
    setSubmitting,
    values,
  };
};

export const options = {
  scopes: {
    label: 'Scope Level',
    content: [],
  },
  allAPIs: {
    label: 'IPs',
  },
  roles: {
    label: 'Roles',
    content: [
      'admin_superuser',
      'all_requests',
      'apikey_requests',
      'bpx_gateway_requests',
      'channel_requests',
      'distributor_requests',
      'internal_requests',
      'fsp_requests',
    ],
  },
};

export const SCOPE_LEVEL_SEPARATOR = {
  [CHANNEL_CODE]: '+_',
  [DISTRIBUTOR_CODE]: '+_',
  [FSP_CODE]: '_',
};

export const scopeTypesRadioOptions = [
  {
    label: CHANNEL_LABEL,
    value: CHANNEL_CODE,
  },
  {
    label: DISTRIBUTOR_LABEL,
    value: DISTRIBUTOR_CODE,
  },
  {
    label: FSP_LABEL,
    value: FSP_CODE,
  },
];
