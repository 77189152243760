import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { selectorById } from 'selectors/oneTimePasscode';
import { load } from 'model/oneTimePasscodeAudit';
import {
  selectorCount,
  selectorGroupedByDays,
} from 'selectors/oneTimePasscodeAudit';
import Audit from '../../../Audit';
import AuditDetailRowRecord from '../../../Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from '../../../Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../../Audit/config/oneTimePasscode/AuditDrawerAppBarTitle';

const ActionsRenderer = ({ item }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Audit
          action={load}
          countSelector={selectorCount}
          DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
          item={item}
          Row={AuditDetailRowRecord}
          selectorGroupedByDays={selectorGroupedByDays}
          selectorId={selectorById}
          Title={AuditDrawerAppBarTitle}
        />
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
