import AuditDetailPaper from 'components/modules/service/Audit/AuditDetailPaper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  VIEW_DETAIL,
  VIEW_LIST,
  VIEW_SYSTEM_DETAILS,
  selector,
} from 'reducers/audit';
import { OFF, ON } from 'consts';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import AuditDetailRowRecordEntry from './AuditDetailRowRecordEntry';
import AuditDetailRowRecordTitle from './AuditDetailRowRecordTitle';
import AuditDetailRowRecordExpandButton from './AuditDetailRowRecordExpandButton';

const useStyles = makeStyles((theme) => ({
  details: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  list: {
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  paper: {
    marginLeft: theme.spacing(2),
    width: 600,
  },
  paperExpanded: {
    marginLeft: `${theme.spacing(2)}px !important `,
  },
}));

const AuditDetailRowRecord = ({ changelog, time, title, ...props }) => {
  const classes = useStyles();
  const audit = useSelector(selector);
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState(audit.view !== VIEW_LIST);
  const [moreEntriesAvailable, setMoreEntriesAvailable] = useState(false);
  const [showUserName, setShowUserName] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const TitleGrid = (
    <AuditDetailRowRecordTitle
      showUserName={showUserName}
      time={time}
      title={title}
      {...props}
    />
  );

  const handleChange = (event, expanded) => {
    setExpanded(expanded);
    setShowUserName(expanded);
  };
  const handleToggle = (value) => {
    setShowMore(value);
  };

  useEffect(() => {
    setExpanded(
      audit.view === VIEW_DETAIL || audit.view === VIEW_SYSTEM_DETAILS
    );
    setShowMore(audit.view === VIEW_SYSTEM_DETAILS);
  }, [audit.view]);

  useEffect(() => {
    if (expanded && changelog && changelog.length) {
      let moreEntriesAvailable = false;

      changelog.forEach(({ basicViewOffOn }) => {
        if (moreEntriesAvailable === false) {
          moreEntriesAvailable = basicViewOffOn === OFF;
        }
      });

      if (moreEntriesAvailable) {
        setMoreEntriesAvailable(moreEntriesAvailable);
      }

      setItems(changelog);
    }
  }, [changelog, expanded]);

  return changelog && changelog.length ? (
    <Accordion
      classes={{
        expanded: classes.paperExpanded,
      }}
      className={classes.paper}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {TitleGrid}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        <Grid container direction="column">
          <Grid item>
            <List className={classes.list}>
              {items
                .filter(
                  ({ basicViewOffOn }) => showMore || basicViewOffOn === ON
                )
                .map(({ columnName, ...itemProps }) => (
                  <AuditDetailRowRecordEntry
                    key={columnName}
                    columnName={columnName}
                    {...props}
                    {...itemProps}
                  />
                ))}
            </List>
          </Grid>
          {expanded && (
            <AuditDetailRowRecordExpandButton
              onClick={handleToggle}
              show={moreEntriesAvailable}
            />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : (
    <AuditDetailPaper className={classes.paper}>{TitleGrid}</AuditDetailPaper>
  );
};

AuditDetailRowRecord.propTypes = {
  changelog: PropTypes.array.isRequired,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AuditDetailRowRecord;
