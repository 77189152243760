import { head } from 'ramda';
import {
  formatCurrency,
  dateAndTime,
  getDdaTypeDerived,
  trimmedDisplayValue,
} from '../../util';

/**
 * Do not change the order, please see
 * https://payrailz.atlassian.net/browse/ON-1385
 */
const getAccountName = ({ accountTitle, ddaType, description, nickName }) => {
  if (nickName) {
    return trimmedDisplayValue(nickName);
  }

  if (accountTitle) {
    return trimmedDisplayValue(accountTitle);
  }

  if (description) {
    return trimmedDisplayValue(description);
  }

  return trimmedDisplayValue(ddaType);
};

export const addDerivedData = (item) => {
  item.accountNameDerived = getAccountName(item);
  item.availBalanceDerived = formatCurrency(item.availBalance);
  item.ddaStatusAtDerived = dateAndTime(item.ddaStatusAt);
  item.ddaTypeDerived = getDdaTypeDerived(item.ddaType);
  item.descriptionDerived = trimmedDisplayValue(item.description);
  item.nickName = trimmedDisplayValue(item.nickName);

  return item;
};

export const hasPayer = ({ expand, accountNbr }) => {
  return accountNbr && Array.isArray(expand) && head(expand) === 'payer';
};
