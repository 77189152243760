import AuditContext from 'components/modules/service/Audit/AuditContext';
import { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';

const AuditDrawerAppBarTitle = () => {
  const { item } = useContext(AuditContext);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography color="inherit" variant="h6">
          Audit Details
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" variant="body1">
          {`Reason: ${item?.otpReason}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuditDrawerAppBarTitle;
