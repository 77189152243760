import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { ON } from 'consts';
import { Features, Mask, MaskPopover } from 'components/common';
import { Grid } from '@material-ui/core';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { StatusGridNoContainer } from 'components/common/StatusGrid';
import { loadAccountAutopay } from 'model/autopay';
import { makeStyles } from '@material-ui/core/styles';
import { selectorAccountAutopay } from 'selectors/autopay';
import {
  unmaskAchNumber,
  unmaskFspDdaId,
  unmaskMicrNumber,
} from 'sdk/internalAccount';
import { useDispatch, useSelector } from 'react-redux';
import DetailsRenderBusinessAccount from './DetailsRenderBusinessAccount';
import DetailsRenderCreditPay from './DetailsRenderCreditPay';
import StatusSelector from './StatusSelector';

const anchorOrigin = { horizontal: 'right', vertical: 'center' };
const transformOrigin = { horizontal: 'right', vertical: 'center' };
const PopoverProps = { anchorOrigin, transformOrigin };

const features = (item) => [
  {
    checked: item.ddaBillPayFromOffOn === ON,
    key: `${item.id}k1`,
    label: 'Pay From',
  },
  {
    checked: item.ddaTransferFromOffOn === ON,
    key: `${item.id}k2`,
    label: 'Transfer From',
  },
  {
    checked: item.ddaTransferToOffOn === ON,
    key: `${item.id}k3`,
    label: 'Transfer To',
  },
];

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({ item, options }) => {
  const classes = useStyles();
  const { autopayRuleAt, fspId, id } = item;
  const dispatch = useDispatch();
  const autopay = useSelector((state) =>
    selectorAccountAutopay(state, item?.id)
  );

  const { statusDerived } = autopay;

  useEffect(() => {
    if (autopayRuleAt && !statusDerived) {
      dispatch(loadAccountAutopay({ ddaId: id, fspId }));
    }
  }, [autopayRuleAt, dispatch, fspId, id, statusDerived]);

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              <ReadOnlyInputWithMenu
                label={
                  item.ddaTypeDerived
                    ? item.ddaTypeDerived
                    : item.externalDdaType
                }
                menuNode={
                  <Features
                    disabled
                    features={features(item)}
                    title="Account Features"
                  />
                }
              />
            }
            label="Type"
            value={item.ddaTypeDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Description"
            value={item.descriptionDerived}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Nickname" value={item.nickName} />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyFormControl
            inputNode={
              <MaskPopover
                api={() =>
                  unmaskFspDdaId({ fspId: options.fspId, id: item.id })
                }
                PopoverProps={PopoverProps}
                value={item.fspDdaIdMask}
              />
            }
            label="FSP DDA ID"
            value={item.fspDdaIdMask}
          />
        </Grid>
      </Grid>
      <Grid container>
        <StatusGridNoContainer
          sizes={[3, 3, 4]}
          status={item.ddaStatus}
          statusNode={<StatusSelector item={item} options={options} />}
          statusText={item.ddaStatusText}
          statusUpdatedAt={item.ddaStatusAtDerived}
        />
        <Grid item xs={2}>
          <ReadOnlyFormControl label="Ontrac DDA ID" value={item.id} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              <Mask
                api={() =>
                  unmaskMicrNumber({ fspId: options.fspId, id: item.id })
                }
                value={item.accountMicrNbrMask}
              />
            }
            label="MICR Account Number"
            value={item.accountMicrNbrMask}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              <Mask
                api={() =>
                  unmaskAchNumber({ fspId: options.fspId, id: item.id })
                }
                value={item.accountAchNbrMask}
              />
            }
            label="ACH Account Number"
            value={item.accountAchNbrMask}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="Available Balance"
            value={item.availBalanceDerived}
          />
        </Grid>
        <DetailsRenderBusinessAccount
          title={item.accountTitle}
          uspsAddress={item.uspsAddress}
        />
        <DetailsRenderCreditPay
          autopay={autopay}
          item={item}
          statusDerived={statusDerived}
        />
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  item: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default DetailsRender;
