import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class Distributor extends Model {
  static get modelName() {
    return 'Distributor';
  }

  static reducer({ type, data }, Distributor) {
    switch (type) {
      case ADD.SUCCESS:
      case LOAD_ID.SUCCESS:
        Distributor.upsert(data.response);
        break;

      case CHECK.ACTION: {
        const distributor = Distributor.withId(data.id);
        if (distributor) {
          distributor.selected = !distributor.selected;

          distributor.update(distributor);
        }
        break;
      }

      case CHECK_ALL.ACTION:
        Distributor.update({ selected: data.selected });
        break;

      case REMOVE.SUCCESS:
        Distributor.withId(data.id).delete();
        break;

      case LOAD.SUCCESS:
      case THROTTLED_LOAD.SUCCESS:
        (data?.response ?? []).forEach((r) => Distributor.upsert(r));
        break;

      case UPDATE.SUCCESS:
        Distributor.update(data.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      channel: attr(),
      createdAt: attr(),
      description: attr(),
      href: attr(),
      id: attr(),
      name: attr(),
      offset: attr(),
      updatedAt: attr(),
    };
  }
}

export const FORM_NAME = 'setupDistributor';
const name = Distributor.modelName;

export const ADD = createAddConst(name);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const LOAD_ID = createRequestConst(`${name}_id`);
export const REMOVE = createDeleteConst(name);
export const THROTTLED_LOAD = createLoadConst(`${name}_throttled`);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const loadId = createAction(LOAD_ID);
export const throttledLoad = createAction(THROTTLED_LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
