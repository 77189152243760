import Action from 'components/common/table/action';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { branch, compose, renderComponent, withProps } from 'recompose';
import { Button, Popover } from '@material-ui/core';
import NotificationCard from './NotificationCard';

const Empty = () => {
  return <td />;
};
const anchorOrigin = { horizontal: 'left', vertical: 'center' };
const transformOrigin = { horizontal: 'right', vertical: 'top' };

const NotificationAction = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} variant="outlined">
        Details <ExpandMore />
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={transformOrigin}
      >
        <NotificationCard row={row} />
      </Popover>
    </>
  );
};

NotificationAction.propTypes = {
  row: PropTypes.object.isRequired,
};

export default compose(
  branch(({ row }) => !row.renderedMsgDecoded, renderComponent(Empty)),
  withProps(() => ({ component: NotificationAction }))
)(Action);
