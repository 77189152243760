import { takeEvery } from 'redux-saga/effects';
import { loadPayeeAutopay, loadAccountAutopay } from 'sdk/autopay';
import { LOAD_PAYEE_AUTOPAY, LOAD_ACCOUNT_AUTOPAY } from 'model/autopay';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD_PAYEE_AUTOPAY.ACTION,
    callAction({
      api: loadPayeeAutopay,
      dataName: ORM,
      type: LOAD_PAYEE_AUTOPAY,
    })
  );

  yield takeEvery(
    LOAD_ACCOUNT_AUTOPAY.ACTION,
    callAction({
      api: loadAccountAutopay,
      dataName: ORM,
      type: LOAD_ACCOUNT_AUTOPAY,
    })
  );
}
