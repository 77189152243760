import Payer from 'model/payer';
import { attr, fk, Model } from 'redux-orm';
import { head } from 'ramda';
import { addDerived } from './util/payee';
import {
  createAction,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class Payee extends Model {
  static get modelName() {
    return 'Payee';
  }

  static reducer({ type, data = {} }, Payee, { Payer }) {
    switch (type) {
      case CHECK_ALL.ACTION:
        Payee.update({ selected: data.selected });
        break;

      case CHECK.ACTION: {
        const payee = Payee.withId(data.id);

        if (payee) {
          payee.selected = !payee.selected;

          payee.update(payee);
        }
        break;
      }

      case LOAD_ID.SUCCESS:
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId).payees.delete();
        } else if (data.props.payerId) {
          Payee.delete();
        }

        data.response.forEach((r) => {
          Payee.upsert(addDerived(r));
        });
        break;

      case LOAD.SUCCESS:
        // Only clear the list, like in a search, if they are the normal
        // number of attributes. If the number of attributes is small, we ran
        // the load call passing in attributes because the load by one call
        // from the API does not support the extend attribute
        if (
          Object.keys(head(data.response) || {}).length > 20 ||
          data.response.length === 0
        ) {
          Payee.delete();
        }
        data.response.forEach((r) => {
          Payee.upsert(addDerived(r));

          if (r.payer) {
            Payer.upsert(r.payer);
          }
        });
        break;

      case UPDATE_NO_COMMIT.ACTION:
        if (Payee.idExists(data.id)) {
          Payee.withId(data.id).delete();
        }
        Payee.upsert(data);
        break;

      case UPDATE.SUCCESS:
        Payee.upsert(data.response);
        break;

      case UPDATE_CHECKED.SUCCESS:
        data.forEach((r) => {
          Payee.upsert({ ...r, selected: false });
        });
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      address1: attr(), // derived
      address2: attr(), // derived
      autopayRuleAt: attr(),
      billerAccountNumberMask: attr(),
      billerId: attr(),
      bpxDeclinedDetails: attr(),
      bpxDeclinedReason: attr(),
      bpxLinkReference: attr(),
      bpxLinkStatus: attr(),
      bpxLinkedAt: attr(),
      bpxSchemeTxId: attr(),
      checkExpediteBizDays: attr(),
      checkStdBizDays: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      deliverCheckExpedite: attr(),
      deliverCheckStd: attr(),
      deliverElecNow: attr(),
      deliverElecStd: attr(),
      deliverElecToday: attr(),
      disbursementOptions: attr(), // derived
      ebillEligibleOffOn: attr(),
      ebillEnrollAt: attr(),
      ebillLastHarvestAt: attr(),
      ebillLastPaymentAmount: attr(),
      ebillLastPaymentOn: attr(),
      ebillLastRefreshOn: attr(),
      ebillNeedsReauthorizationOffOn: attr(),
      ebillReauthorizationAt: attr(),
      ebillStatus: attr(), // derived
      ebillUnenrollAt: attr(),
      ebillerUpdownStatus: attr(),
      elecStdBizDays: attr(),
      enrollmentDate: attr(), // derived
      fsp: attr(),
      fspId: attr(),
      fullAddress: attr(), // derived
      href: attr(),
      id: attr(),
      insUserId: attr(),
      lastScheduleCreatedAt: attr(),
      name: attr(),
      nickName: attr(),
      nickNameLabel: attr(), // derived
      offset: attr(),
      payeeMemo: attr(),
      payeeStatus: attr(),
      payeeStatusAt: attr(),
      payeeStatusAtLabel: attr(), // derived
      payer: attr(),
      payerEbillerAccountId: attr(),
      payerEbillernetEbillerId: attr(),
      payerId: fk(Payer.modelName, 'payees'),
      rppsBillerId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
      userCategory: attr(),
      uspsAddress: attr(),
    };
  }
}

const name = Payee.modelName;

export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const LOAD_ID = createLoadConst(`${name}_id`);
export const UPDATE = createUpdateConst(name);
export const UPDATE_CHECKED = createUpdateConst(`${name}_checked`);
export const UPDATE_NO_COMMIT = createRequestConst(name);

export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const update = createAction(UPDATE);
export const updateChecked = createAction(UPDATE_CHECKED);
export const updateNoCommit = createAction(UPDATE_NO_COMMIT);
