import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { isNilOrEmpty } from 'util/index';
import AuditContext from './AuditContext';

const useStyles = makeStyles(() => ({
  label: { whiteSpace: 'nowrap' },
}));

const label = 'Audit Details';

const Audit = ({
  action,
  actionParams,
  buttonLabel,
  countSelector,
  DrawerEndAdornment,
  item,
  Row,
  selectorGroupedByDays,
  selectorId,
  selectorProps,
  Title,
}) => {
  const { open } = useContext(AuditContext);
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    open({
      action,
      actionParams,
      countSelector,
      DrawerEndAdornment,
      item,
      Row,
      selectorGroupedByDays,
      selectorId,
      selectorProps,
      Title,
    });
  };

  return (
    <Button
      classes={{ label: classes.label }}
      onClick={handleClick}
      variant="outlined"
    >
      {!isNilOrEmpty(buttonLabel) ? buttonLabel : label}
    </Button>
  );
};

Audit.propTypes = {
  action: PropTypes.func.isRequired,
  actionParams: PropTypes.object,
  buttonLabel: PropTypes.string,
  countSelector: PropTypes.func.isRequired,
  DrawerEndAdornment: PropTypes.elementType,
  item: PropTypes.object.isRequired,
  Row: PropTypes.elementType.isRequired,
  selectorGroupedByDays: PropTypes.func.isRequired,
  selectorId: PropTypes.func,
  selectorProps: PropTypes.object,
  Title: PropTypes.func.isRequired,
};

Audit.defaultProps = {
  actionParams: null,
  buttonLabel: label,
  DrawerEndAdornment: null,
  selectorId: null,
  selectorProps: null,
};

export default memo(Audit);
