import AuditContext from 'components/modules/service/Audit/AuditContext';
import { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const AuditDrawerAppBarTitle = () => {
  const { item, selectorId } = useContext(AuditContext);
  const { name, nickName } = useSelector((state) => selectorId(state, item));

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography color="inherit" variant="h6">
          Audit Details - {name || nickName || 'No Name Defined'}
        </Typography>
      </Grid>
      {name && nickName && (
        <Grid item>
          <Typography color="textSecondary" variant="body1">
            Nickname: {nickName}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AuditDrawerAppBarTitle;
