import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withProps } from 'recompose';
import { selectorId } from 'selectors/eBillEnrollment';
import { load } from 'model/eBillEnrollmentAudit';
import {
  selectorCount,
  selectorGroupedByDays,
} from 'selectors/eBillEnrollmentAudit';
import Action from 'components/common/table/action';
import Audit from '../../../Audit';
import AuditDetailRowRecord from '../../../Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from '../../../Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../../Audit/config/eBillEnrollment/AuditDrawerAppBarTitle';

const EBillEnrollmentAction = ({ row }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Audit
          action={load}
          countSelector={selectorCount}
          DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
          item={row}
          Row={AuditDetailRowRecord}
          selectorGroupedByDays={selectorGroupedByDays}
          selectorId={selectorId}
          Title={AuditDrawerAppBarTitle}
          titleField="netUserIdStr"
        />
      </Grid>
    </Grid>
  );
};

EBillEnrollmentAction.propTypes = {
  row: PropTypes.object.isRequired,
};

export default withProps(() => ({ component: EBillEnrollmentAction }))(Action);
