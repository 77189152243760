import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class UserFspConfig extends Model {
  static get modelName() {
    return 'UserFspConfig';
  }

  static reducer({ type, data }, UserFspConfig) {
    if (type === LOAD_USER_FSP_CONFIG.SUCCESS) {
      UserFspConfig.upsert(data.response);
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      a2aConfig: attr(),
      a2aInOffOn: attr(),
      a2aOutOffOn: attr(),
      bpConfig: attr(),
      bpOffOn: attr(),
      brandConfig: attr(),
      cdHoldAccount: attr(),
      checkConfig: attr(),
      coreProcessorId: attr(),
      coreStatementId: attr(),
      createdAt: attr(),
      creditPayOffOn: attr(),
      daysToFund: attr(),
      displayAccountNbrMaskLong: attr(),
      displayAccountNbrMaskShort: attr(),
      fsp: attr(),
      fspId: attr(),
      fspLogo: attr(),
      fspName: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      notifyConfig: attr(),
      otpOffOn: attr(),
      otpConfig: attr(),
      p2pConfig: attr(),
      p2pOffOn: attr(),
      payerId: attr(),
      pilotEnabledOffOn: attr(),
      products: attr(),
      realtimeFundingService: attr(),
      ssoA2AOffOn: attr(),
      ssoBPOffOn: attr(),
      ssoConfig: attr(),
      ssoP2POffOn: attr(),
      transactA2AOffOn: attr(),
      transactBPOffOn: attr(),
      transactP2POffOn: attr(),
      updUserId: attr(),
      updatedAt: attr(),
      userId: attr(),
      windowId: attr(),
    };
  }
}

export const LOAD_USER_FSP_CONFIG = createLoadConst(UserFspConfig.modelName);

export const loadUserFSPConfig = createAction(LOAD_USER_FSP_CONFIG);
