import PropTypes from 'prop-types';
import { load } from 'model/scheduledTransactionAudit';
import {
  selectorCount,
  selectorGroupedByDays,
} from 'selectors/scheduledTransactionAudit';
import Audit from 'components/modules/service/Audit';
import AuditDetailRowRecord from 'components/modules/service/Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from 'components/modules/service/Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from 'components/modules/service/Audit/config/transaction/AuditDrawerAppBarTitle';

const ScheduledAudit = ({ buttonLabel, item, selectorId, selectorProps }) => {
  return (
    <Audit
      action={load}
      buttonLabel={buttonLabel}
      countSelector={selectorCount}
      DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
      item={item}
      Row={AuditDetailRowRecord}
      selectorGroupedByDays={selectorGroupedByDays}
      selectorId={selectorId}
      selectorProps={selectorProps}
      Title={AuditDrawerAppBarTitle}
    />
  );
};

ScheduledAudit.propTypes = {
  buttonLabel: PropTypes.string,
  item: PropTypes.object,
  selectorId: PropTypes.func.isRequired,
  selectorProps: PropTypes.object,
};

ScheduledAudit.defaultProps = {
  buttonLabel: null,
  item: null,
  selectorProps: null,
};

export default ScheduledAudit;
