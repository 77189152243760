import TextSkeleton from 'components/common/TextSkeleton';
import { ASC, ID } from 'consts';
import { compose, filter, head, isNil, prop, sortBy, toLower } from 'ramda';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { hasExecutePriv } from 'util/rbac';
import { makeStyles } from '@material-ui/core/styles';
import { orm } from 'model';
import { selector as selectedFspSelector } from 'reducers/selectedFspId';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 'revert',
  },
}));

const TitleTextSkeleton = () => {
  const classes = useStyles();

  return <TextSkeleton className={classes.root} height={38} width={250} />;
};

const SKELETON_TITLE = <TitleTextSkeleton />;
const SKELETON_15 = <TextSkeleton width="15%" />;
const SKELETON_25 = <TextSkeleton width="25%" />;
const SKELETON_32 = <TextSkeleton width="32%" />;
const SKELETON_45 = <TextSkeleton width="45%" />;
const SKELETON_55 = <TextSkeleton width="55%" />;

const itemAsSkeletons = {
  channelId: SKELETON_32,
  createdAtLabel: SKELETON_55,
  database: SKELETON_15,
  description: SKELETON_55,
  distributorId: SKELETON_25,
  erouteId: SKELETON_15,
  fspStatus: SKELETON_25,
  isEmpty: true,
  name: SKELETON_45,
  routeId: SKELETON_15,
  shortName: SKELETON_45,
  title: SKELETON_TITLE,
  updatedAtLabel: SKELETON_55,
};

const mapDescriptionNameToTitle = (fsps) => {
  return fsps?.map(({ description, id, name }) => ({
    id,
    title: description?.trim() || name?.trim(),
  }));
};
const sortedByTitle = sortBy(compose(toLower, prop('title')));
const withExecutePrivileges = filter(hasExecutePriv);

export const executePrivFspsSelector = createReselectSelector(
  ({ auth }) => auth?.fsps,
  (items) =>
    sortedByTitle(mapDescriptionNameToTitle(withExecutePrivileges(items)))
);

export const selectedFspIdSelector = createReselectSelector(
  executePrivFspsSelector,
  selectedFspSelector,
  (allTrustedIds = [], selectedId) => {
    if ((selectedId === 0 || isNil(selectedId)) && allTrustedIds.length) {
      return head(allTrustedIds)?.id;
    }

    return selectedId;
  }
);

export const selector = createSelector(orm.FSP);

export const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ FSP }, id) => {
    if (FSP.idExists(id)) {
      const item = FSP.withId(id);

      item.set('isEmpty', false);

      return item.ref;
    }

    return { ...itemAsSkeletons, id };
  }
);

/**
 * Only returns back fields which may be updated
 */
export const selectorUpdateId = createSelector(
  orm,
  (state, id) => id,
  ({ FSP }, id) => {
    if (FSP.idExists(id)) {
      const { description, erouteId, fspStatus, name, routeId, shortName } =
        FSP.withId(id).ref;

      return {
        description,
        erouteId,
        fspStatus,
        id,
        name,
        routeId,
        shortName,
      };
    }

    return { ...itemAsSkeletons, id };
  }
);

export const selectorCount = createSelector(orm, ({ FSP }) => FSP.count());

export const selectorCountWithLatestEntry = createSelector(orm, ({ FSP }) =>
  FSP.orderBy(ID, ASC).toRefArray()
);
