import { useContext } from 'react';
import { makeStyles, List } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AuditContext from './AuditContext';
import AuditDetail from './AuditDetail';

const useStyles = makeStyles((theme) => ({
  list: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}));

const AuditDetails = () => {
  const classes = useStyles();
  const { item, selectorGroupedByDays, selectorProps } =
    useContext(AuditContext);
  const items = useSelector((state) =>
    selectorGroupedByDays(state, selectorProps || item)
  );

  return (
    <List className={classes.list}>
      {items.map(({ id, ...itemProps }, index) => (
        <AuditDetail
          key={id}
          last={items.length - 1 === index}
          {...itemProps}
        />
      ))}
    </List>
  );
};

export default AuditDetails;
