import PropTypes from 'prop-types';
import { Collapse, Grid } from '@material-ui/core';
import { load } from 'model/payerAudit';
import { makeStyles } from '@material-ui/core/styles';
import { profileSelector, selectorAsName } from 'selectors/payer';
import { selectorCount, selectorGroupedByDays } from 'selectors/payerAudit';
import { useSelector } from 'react-redux';
import Audit from '../../Audit';
import AuditDetailRowRecord from '../../Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from '../../Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../Audit/config/shared/AuditDrawerAppBarTitle';
import Limits from './Limits';
import Tickets from './Tickets';
import ViewAs from './ViewAs';
import { PayerUserButtonProvider } from '../PayerOrPayerUserButton';

const useStyles = makeStyles((theme) => ({
  actions: {
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
  },
}));

const ProfileButtons = ({ expanded, fspId, id }) => {
  const classes = useStyles();
  const item = useSelector((state) => profileSelector(state, id));

  return !item.isEmpty ? (
    <Collapse className={classes.actions} in={expanded}>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Tickets fspId={item.zfspId} id={id} />
        </Grid>
        <Grid item>
          <Limits fspId={fspId} id={id} limitType={item.limitType} />
        </Grid>
        <Grid item>
          <Audit
            action={load}
            countSelector={selectorCount}
            DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
            item={item}
            Row={AuditDetailRowRecord}
            selectorGroupedByDays={selectorGroupedByDays}
            selectorId={selectorAsName}
            Title={AuditDrawerAppBarTitle}
          />
        </Grid>
        <Grid item>
          <PayerUserButtonProvider>
            <ViewAs fspId={fspId} payerId={id} payerType={item.payerType} />
          </PayerUserButtonProvider>
        </Grid>
      </Grid>
    </Collapse>
  ) : null;
};

ProfileButtons.propTypes = {
  expanded: PropTypes.bool.isRequired,
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default ProfileButtons;
