import {
  defaultTo,
  equals,
  isEmpty,
  isNil,
  not,
  prepend,
  replace,
  reverse,
  toUpper,
} from 'ramda';
import {
  ON,
  P2P_DIRECT_OFF_ON,
  PERSON_TO_PERSON,
  TRANSACTION_TYPE,
  VENMO_DIRECT_CODE,
} from 'consts';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { formatSearchBys } from 'components/common/searchByUtils';
import { isLoadingState } from 'reducers/loading';
import { selector } from 'selectors/pageSetting';
import { update } from 'model/pageSetting';
import { withHandlers, lifecycle } from 'recompose';

export const deletedOnlyField = [
  {
    defaultValue: null,
    field: 'deletedOnly',
    id: 0,
    operator: 'field',
    value: 'on',
  },
];

const DAY = 'd';
export const YEAR_MONTH_DAY = 'YYYY-MM-DD';

const addOrderBy = (props, orderByValue, order) => {
  props[`orderBy${replace(/^./, toUpper, orderByValue || '')}`] = order;
};

export const formatOrderOffsetLimit = ({
  order,
  orderBy,
  page,
  rowsPerPage,
}) => {
  const props = {
    offset: page * rowsPerPage,
    limit: rowsPerPage + 1,
  };

  if (orderBy) {
    if (Array.isArray(orderBy)) {
      orderBy.forEach((orderByValue) => {
        addOrderBy(props, orderByValue, order);
      });
    } else {
      addOrderBy(props, orderBy, order);
    }
  }

  return props;
};

const formatRange = (days, start) => [
  moment(start).format(YEAR_MONTH_DAY), // DO NOT USE default format - not for display
  moment().add(days, DAY).format(YEAR_MONTH_DAY), // DO NOT USE default format - not for display
];

const formatSearchBysUsingDays = ({ field, value, start }, searchBys) => {
  let retVal = [
    {
      field,
      operator: 'fieldRange',
      value:
        value > 0
          ? start
            ? formatRange(value, start)
            : ['', formatRange(value, start)[1]]
          : reverse(formatRange(value, start)),
    },
  ];

  if (searchBys && searchBys.length) {
    retVal = [...retVal, ...searchBys];
  }

  return retVal;
};

export const usePageConnect = (data) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => {
    return isLoadingState(state, data.actionType);
  });
  const pageSettings = useSelector((state) => {
    return selector(
      state,
      data.loadParams?.payerId || 0,
      data.settingType,
      data.defaultPageSettings
    );
  });

  return {
    ...pageSettings,
    isLoading,
    update: (props) => {
      dispatch(update(props));
    },
  };
};

export const pageConnect = connect(
  (state, { actionType, defaultPageSettings, loadParams, settingType }) => ({
    isLoading: isLoadingState(state, actionType),
    ...selector(
      state,
      loadParams?.payerId || 0,
      settingType,
      defaultPageSettings
    ),
  }),
  { update }
);

export const usePageHandlers = ({
  clearAction,
  loadAction,
  loadParams,
  noSearch,
  order,
  order: currentOrder,
  orderBy,
  orderBy: currentOrderBy,
  page: currentPage,
  rowsPerPage,
  rowsPerPage: currentRowsPerPage,
  rowsPerPageOptions,
  searchBys,
  searchBys: currentSearchBys,
  settingType,
  subSearchBy,
  subSearchBy: currentSubSearch,
  update,
}) => {
  const handleSearch = (
    searchBys,
    subSearchBy,
    daysFilter,
    useCurrentSearchBys = false
  ) => {
    const startPage = 0;

    if (!isNil(daysFilter)) {
      searchBys = formatSearchBysUsingDays(daysFilter, searchBys);
    } else if (useCurrentSearchBys) {
      searchBys = currentSearchBys;
    }

    update({
      payerId: loadParams?.payerId || 0,
      page: startPage,
      rowsPerPageOptions,
      rowsPerPage,
      order,
      orderBy,
      searchBys: subSearchBy
        ? prepend(subSearchBy, searchBys)
        : currentSubSearch
        ? prepend(currentSubSearch, searchBys)
        : searchBys,
      type: settingType,
    });

    const formattedSearchBys = formatSearchBys(searchBys || []);

    if (noSearch || not(isEmpty(formattedSearchBys))) {
      const orderOffset = formatOrderOffsetLimit({
        order,
        orderBy,
        page: startPage,
        rowsPerPage,
      });

      const searchParams = subSearchBy
        ? prepend(subSearchBy, searchBys)
        : currentSubSearch
        ? prepend(currentSubSearch, searchBys)
        : searchBys;

      const formattedSearchBys = formatSearchBys(searchParams);

      // Specialty search scenario -https://payrailz.atlassian.net/browse/ON-1136
      // I don't like this solution, but it works and does not cause
      // issues with re-writing a lot of <Select />
      if (formattedSearchBys[TRANSACTION_TYPE] === VENMO_DIRECT_CODE) {
        formattedSearchBys[P2P_DIRECT_OFF_ON] = ON;
        formattedSearchBys[TRANSACTION_TYPE] = PERSON_TO_PERSON;
      }

      loadAction({
        ...orderOffset,
        ...formattedSearchBys,
        ...loadParams,
      });
    } else {
      clearAction(loadParams);
    }
  };

  const handleChange = (
    event,
    { orderBy, order, page, rowsPerPage, initialLoad = false }
  ) => {
    let startPage = defaultTo(currentPage, page);
    const nextOrPrevPage = not(equals(page, currentPage));

    if (
      not(equals(rowsPerPage, currentRowsPerPage)) ||
      not(equals(order, currentOrder)) ||
      not(equals(orderBy, currentOrderBy)) ||
      nextOrPrevPage ||
      initialLoad
    ) {
      if (not(nextOrPrevPage)) {
        startPage = 0;
      }
      const combineSearchBys = subSearchBy
        ? prepend(subSearchBy, searchBys)
        : searchBys;
      const pagingObject = {
        order: defaultTo(currentOrder, order),
        orderBy: defaultTo(currentOrderBy, orderBy),
        page: startPage,
        rowsPerPage: defaultTo(currentRowsPerPage, rowsPerPage),
      };

      update({
        ...pagingObject,
        payerId: loadParams?.payerId || 0,
        rowsPerPageOptions,
        searchBys: combineSearchBys,
        type: settingType,
      });

      let formattedSearchBys = formatSearchBys(searchBys);

      if (noSearch || not(isEmpty(formattedSearchBys))) {
        formattedSearchBys = subSearchBy
          ? formatSearchBys(combineSearchBys)
          : formattedSearchBys;
        loadAction({
          ...formatOrderOffsetLimit(pagingObject),
          ...formattedSearchBys,
          ...loadParams,
        });
      }
    }
  };

  return [handleSearch, handleChange];
};

export const pageHandlers = withHandlers({
  handleSearch:
    ({
      clearAction,
      loadAction,
      loadParams,
      noSearch,
      order,
      orderBy,
      rowsPerPage,
      rowsPerPageOptions,
      searchBys: currentSearchBys,
      settingType,
      subSearchBy: currentSubSearch,
      update,
    }) =>
    (searchBys, subSearchBy, daysFilter, useCurrentSearchBys = false) => {
      const startPage = 0;

      if (!isNil(daysFilter)) {
        searchBys = formatSearchBysUsingDays(daysFilter, searchBys);
      } else if (useCurrentSearchBys) {
        searchBys = currentSearchBys;
      }

      update({
        payerId: loadParams?.payerId || 0,
        page: startPage,
        rowsPerPageOptions,
        rowsPerPage,
        order,
        orderBy,
        searchBys: subSearchBy
          ? prepend(subSearchBy, searchBys)
          : currentSubSearch
          ? prepend(currentSubSearch, searchBys)
          : searchBys,
        type: settingType,
      });

      const formattedSearchBys = formatSearchBys(searchBys || []);

      if (noSearch || not(isEmpty(formattedSearchBys))) {
        const orderOffset = formatOrderOffsetLimit({
          order,
          orderBy,
          page: startPage,
          rowsPerPage,
        });

        const searchParams = subSearchBy
          ? prepend(subSearchBy, searchBys)
          : currentSubSearch
          ? prepend(currentSubSearch, searchBys)
          : searchBys;

        const formattedSearchBys = formatSearchBys(searchParams);

        // Specialty search scenario -https://payrailz.atlassian.net/browse/ON-1136
        // I don't like this solution, but it works and does not cause
        // issues with re-writing a lot of <Select />
        if (formattedSearchBys[TRANSACTION_TYPE] === VENMO_DIRECT_CODE) {
          formattedSearchBys[P2P_DIRECT_OFF_ON] = ON;
          formattedSearchBys[TRANSACTION_TYPE] = PERSON_TO_PERSON;
        }

        loadAction({
          ...orderOffset,
          ...formattedSearchBys,
          ...loadParams,
        });
      } else {
        clearAction(loadParams);
      }
    },
  handleChange:
    ({
      loadAction,
      loadParams,
      noSearch,
      order: currentOrder,
      orderBy: currentOrderBy,
      page: currentPage,
      rowsPerPage: currentRowsPerPage,
      rowsPerPageOptions,
      searchBys,
      settingType,
      subSearchBy,
      update,
    }) =>
    (event, { orderBy, order, page, rowsPerPage, initialLoad = false }) => {
      let startPage = defaultTo(currentPage, page);
      const nextOrPrevPage = not(equals(page, currentPage));

      if (
        not(equals(rowsPerPage, currentRowsPerPage)) ||
        not(equals(order, currentOrder)) ||
        not(equals(orderBy, currentOrderBy)) ||
        nextOrPrevPage ||
        initialLoad
      ) {
        if (not(nextOrPrevPage)) {
          startPage = 0;
        }
        const combineSearchBys = subSearchBy
          ? prepend(subSearchBy, searchBys)
          : searchBys;
        const pagingObject = {
          order: defaultTo(currentOrder, order),
          orderBy: defaultTo(currentOrderBy, orderBy),
          page: startPage,
          rowsPerPage: defaultTo(currentRowsPerPage, rowsPerPage),
        };

        update({
          ...pagingObject,
          payerId: loadParams?.payerId || 0,
          rowsPerPageOptions,
          searchBys: combineSearchBys,
          type: settingType,
        });

        let formattedSearchBys = formatSearchBys(searchBys);

        if (noSearch || not(isEmpty(formattedSearchBys))) {
          formattedSearchBys = subSearchBy
            ? formatSearchBys(combineSearchBys)
            : formattedSearchBys;
          loadAction({
            ...formatOrderOffsetLimit(pagingObject),
            ...formattedSearchBys,
            ...loadParams,
          });
        }
      }
    },
});
export const withRefreshOperation = withHandlers({
  refetchData:
    ({ loadParams, settingType, handleChange, update }) =>
    () => {
      handleChange({}, {});
      update({
        payerId: loadParams?.payerId || 0,
        type: settingType,
        actionRefetchData: false,
      });
    },
});

export const pageLifeCycle = lifecycle({
  componentDidMount() {
    const { handleSearch, searchBys } = this.props;

    handleSearch(searchBys);
  },
  componentWillUnmount() {
    const { resetPageSettingsOnCleanup } = this.props;

    if (resetPageSettingsOnCleanup) {
      const {
        clearAction,
        defaultPageSettings = {},
        loadParams,
        settingType,
        update,
      } = this.props;

      clearAction(loadParams);

      update({
        payerId: loadParams?.payerId || 0,
        type: settingType,
        ...defaultPageSettings,
      });
    }
  },
});

export default [pageConnect, pageHandlers, pageLifeCycle];
