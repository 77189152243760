import PropTypes from 'prop-types';
import { useRef, useState, useCallback, useEffect } from 'react';
import { ADD_MESSAGE } from 'util/actions';
import { clearTransactAsData, selector } from 'reducers/transactAs';
import { client } from 'util/sdk';
import { getAuth } from 'util/index';
import { update as updatePageSetting } from 'model/pageSetting';
import { useIframeLoadScript } from 'hooks';
import { DISBURSE_TYPE_P2P_DIRECT_CODE, SSO_CODE, V2 } from 'consts';
import { useSelector, useDispatch } from 'react-redux';
import {
  waitOn,
  isP2P,
  getLandingType,
  getSuccessMessage,
  SsoVersion,
} from './utils';

const iframeStyles = {
  width: '100vw',
  maxWidth: '100%',
  height: '100vh',
  border: 0,
};

const isV2 = (type) =>
  type?.includes(V2) || type === DISBURSE_TYPE_P2P_DIRECT_CODE;

const startWebTransactAs = (
  dispatch,
  iframeWindow,
  transactAsData,
  onError,
  exitSso,
  adminUserId,
  type
) => {
  const { registerData, data } = transactAsData;
  const userId = registerData.user.id;
  const { ssoToken } = registerData.sso;
  const transactConfig = {
    showInDialog: false,
    ssoToken,
    payerId: registerData.payer.id,
    userId,
    fspId: registerData.payer.fsp.id,
    env: client.getEnvironment(),
    adminUserId,
    // V2: destinationId => if P2P then recipientId. If BP then payeeId
    destinationId: isP2P(type) ? data.recipientId : data.payeeId,
    // V1 needs recipientId for P2P transactions
    recipientId: data.recipientId,
    transactionId: data.transactionId,
    type,
    // V1 needs payeeId for BP transactions
    payeeId: data.payeeId,
    onExit: exitSso,
    onError,
    onSuccess: (transaction, operation) => {
      dispatch(
        updatePageSetting({
          payerId: registerData.payer.id,
          type: 'scheduledTransaction',
          actionRefetchData: true,
        })
      );

      dispatch({
        type: ADD_MESSAGE.ACTION,
        data: getSuccessMessage(type, transaction, operation),
      });
      exitSso();
    },
  };

  if (isV2(type)) {
    iframeWindow.PayrailzWeb.mount(
      transactConfig,
      iframeWindow.document.getElementById('root')
    );
  } else {
    iframeWindow.PayrailzWebTransact.init(transactConfig);
  }
};

const EmbeddedSso = ({ exitSso }) => {
  const dispatch = useDispatch();
  const { id: adminUserId } = useSelector(getAuth);
  const transactAsData = useSelector(selector);
  const iframeRef = useRef();
  const [isIframeLoaded, setIframeLoaded] = useState(false);
  const { registerData, data } = transactAsData;
  const { links } = registerData.sso;
  // This getLandingType method will determine what sso version will be used: v1 or v2.
  // V1 return examples: bp, p2p, activity.
  // V2 return examples: v2-bp, v2-p2p, v2-a2a.
  const type = getLandingType(
    links,
    data.recipientId,
    data.payeeId,
    data.transactionId,
    data.transactionType
  );

  const onError = useCallback(
    (data) => {
      dispatch({
        error: {
          message: 'There was an unexpected error, please try again later',
        },
        data,
        type: ADD_MESSAGE.FAILURE,
      });
      exitSso();
    },
    [dispatch, exitSso]
  );

  const handleScriptLoad = useCallback(() => {
    const { contentWindow: iframeWindow } = iframeRef.current;

    let isWebTransactReady;
    if (isV2(type)) {
      isWebTransactReady = () => iframeWindow.PayrailzWeb;
    } else {
      isWebTransactReady = () => iframeWindow.PayrailzWebTransact;
    }
    const start = () =>
      startWebTransactAs(
        dispatch,
        iframeWindow,
        transactAsData,
        onError,
        exitSso,
        adminUserId,
        type
      );

    waitOn(isWebTransactReady, 3, start, onError);
  }, [
    iframeRef,
    transactAsData,
    onError,
    exitSso,
    adminUserId,
    dispatch,
    type,
  ]);

  const ssoData = SsoVersion[isV2(type) ? V2 : SSO_CODE];
  const scriptUri = `${transactAsData.registerData.sso.uri}${ssoData.scriptName}`;
  const htmlContent = `${process.env.PUBLIC_URL}${ssoData.html}`;

  useIframeLoadScript(
    isIframeLoaded,
    iframeRef,
    scriptUri,
    handleScriptLoad,
    onError
  );

  useEffect(() => {
    return () => {
      dispatch(clearTransactAsData());
    };
  }, [dispatch]);

  const handleLoad = () => setIframeLoaded(true);

  return (
    <iframe
      ref={iframeRef}
      onLoad={handleLoad}
      src={htmlContent}
      style={iframeStyles}
      title="Transact as Payer"
    />
  );
};

EmbeddedSso.propTypes = {
  exitSso: PropTypes.func.isRequired,
};

export default EmbeddedSso;
