import PropTypes from 'prop-types';
import { handleStopPropagation } from 'consts';
import { Menu as MuiMenu } from '@material-ui/core';
import { selectorPayerId } from 'selectors/payerUser';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';

const Menu = ({
  action,
  anchorEl,
  onClick,
  onClose,
  open,
  payerId,
  selector,
  submenu,
  transactAs,
}) => {
  const payerUsers = useSelector((state) => selectorPayerId(state, payerId));
  const item = useSelector((state) => selector(state, payerId));

  return (
    <MuiMenu
      anchorEl={anchorEl}
      onClick={handleStopPropagation}
      onClose={onClose}
      open={open}
    >
      {payerUsers.map(({ id, ...payerUser }) => (
        <MenuItem
          key={id}
          action={action}
          item={item}
          onClick={onClick}
          onClose={onClose}
          payerUser={payerUser}
          submenu={submenu}
          transactAs={transactAs}
        />
      ))}
    </MuiMenu>
  );
};

Menu.propTypes = {
  action: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  payerId: PropTypes.number.isRequired,
  selector: PropTypes.func.isRequired,
  submenu: PropTypes.bool,
  transactAs: PropTypes.bool,
};

Menu.defaultProps = {
  anchorEl: null,
  open: false,
  submenu: false,
  transactAs: false,
};

export default Menu;
