import {
  ACH,
  CHECK,
  CHECK_NUM,
  CONVERTED_LABEL,
  DEBIT_CARD,
  DELIVER_CHECK_EXPEDITE,
  DELIVER_CHECK_STD,
  DELIVER_ELEC_NOW,
  DELIVER_ELEC_STD,
  DISBURSE_TYPE_P2P_ACH,
  DISBURSE_TYPE_P2P_DEBIT,
  DISBURSE_TYPE_P2P_PAYOUT,
  DISBURSE_TYPE_P2P_VENMO,
  ELECTRONIC,
  EXPEDITED,
  IMMEDIATE,
  NA_VALUE,
  NEXT_DAY,
  P2P_PAYOUT_LABEL,
  PERSON_TO_PERSON,
  UNAVAILABLE_VALUE,
  USD,
  VENMO,
  isEqual,
} from 'consts';
import { isFIDirect as isFIDirectUtil } from 'components/modules/service/PayerDetail/Transactions/History/config/DetailsRender/consts';
import { isNilOrEmpty, formatCurrency, isOn } from 'util/index';

const FEE = ' (Fee ';
const frequencyMap = {
  everyFourMonths: 'Every four months',
  everyFourWeeks: 'Every 4 weeks',
  everyOtherMonth: 'Every other month',
  everyTwoWeeks: 'Every 2 weeks',
  monthly: 'Monthly',
  once: 'Just this once',
  quarterly: 'Quarterly',
  twicePerMonth: 'Twice per month',
  twicePerYear: 'Twice per year',
  weekly: 'Weekly',
  yearly: 'Annually',
};

export const getP2PDisbursementType = (disburseType) => {
  switch (disburseType) {
    case DISBURSE_TYPE_P2P_ACH:
      return ACH;

    case DISBURSE_TYPE_P2P_DEBIT:
      return DEBIT_CARD;

    case DISBURSE_TYPE_P2P_PAYOUT:
      return P2P_PAYOUT_LABEL;

    case DISBURSE_TYPE_P2P_VENMO:
      return VENMO;

    default:
      return NA_VALUE;
  }
};

const checkNumber = ({ checkNumber }) => {
  return !isNilOrEmpty(checkNumber) ? checkNumber : UNAVAILABLE_VALUE;
};
const fee = ({ feeAmount }) => {
  return `${FEE}${
    !isNilOrEmpty(feeAmount)
      ? `${formatCurrency(feeAmount, { code: USD })})`
      : UNAVAILABLE_VALUE
  }`;
};

export const getDeliveryMethod = (item, scheduled) => {
  if (isOn(item?.conversionDataOffOn)) {
    return CONVERTED_LABEL;
  }

  // Transaction History -  P2P Delivery Method for Real Time and ACH
  // https://payrailz.atlassian.net/browse/ON-859
  if (isEqual(item?.transactionType, PERSON_TO_PERSON)) {
    switch (item?.deliver?.toUpperCase()) {
      case DELIVER_ELEC_NOW.toUpperCase():
        return IMMEDIATE;

      case DELIVER_ELEC_STD.toUpperCase():
        return NEXT_DAY;

      default:
        return ELECTRONIC;
    }
  }

  if (scheduled) {
    switch (item?.deliver?.toUpperCase()) {
      case DELIVER_CHECK_STD.toUpperCase():
        return CHECK;

      case DELIVER_CHECK_EXPEDITE.toUpperCase():
        return `${EXPEDITED} ${item?.feeAmount ? fee(item) : ''}`;

      default:
        return ELECTRONIC;
    }
  }

  // History Transaction
  switch (item?.deliver?.toUpperCase()) {
    case DELIVER_CHECK_STD.toUpperCase():
      return `${CHECK_NUM}${checkNumber(item)}`;

    case DELIVER_CHECK_EXPEDITE.toUpperCase():
      return `${EXPEDITED} ${CHECK_NUM}${checkNumber(item)}${fee(item)}`;

    default:
      return ELECTRONIC;
  }
};

export const getFrequency = (frequency) => frequencyMap[frequency] ?? frequency;

export const isFIDirect = isFIDirectUtil;
