import PropTypes from 'prop-types';
import { Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectorRows } from 'selectors/limitRule';
import { useSelector } from 'react-redux';
import { useSupportedTypesSelector } from 'hooks/selectors/useUserFspConfigSelectors';
import LimitsTableBusyRow from './LimitsTableBusyRow';
import LimitsTableHead from './LimitsTableHead';
import LimitsTableRow from './LimitsTableRow';
import LimitsTableNoResultsRow from './LimitsTableNoResultsRow';
import { useLimits } from '../LimitsContext';

const useStyles = makeStyles(() => ({
  table: {
    tableLayout: 'fixed',
  },
}));

const LimitsTable = ({ edit }) => {
  const { limitType, fspId, payerId } = useLimits();
  const classes = useStyles();
  const products = useSupportedTypesSelector(fspId, payerId);
  const rows = useSelector((state) =>
    selectorRows(state, { payerId, products })
  );

  return (
    <Table classes={{ root: classes.table }}>
      <LimitsTableHead edit={edit} />
      <TableBody>
        <LimitsTableBusyRow edit={edit} />
        {rows.map((row) => (
          <LimitsTableRow
            key={row.id}
            edit={edit}
            payerId={payerId}
            row={row}
          />
        ))}
        <LimitsTableNoResultsRow limitType={limitType} />
      </TableBody>
    </Table>
  );
};

LimitsTable.propTypes = {
  edit: PropTypes.bool,
};

LimitsTable.defaultProps = {
  edit: false,
};

export default LimitsTable;
