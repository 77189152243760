import PropTypes from 'prop-types';
import { executePrivFspsSelector } from 'selectors/fsp';
import { loadById } from 'model/payer';
import { loadByPayerId as loadUsersByPayerId } from 'model/user';
import { loadUserFSPConfig } from 'model/userFspConfig';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useEffect, useRef } from 'react';
import { selectorFirstByPayerId } from 'selectors/user';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFSPId, usePayerId, useScrollState } from 'hooks';
import { viewHeightWithoutAppbar } from 'util/index';
import Accounts from './Accounts';
import ActionableAlerts from './ActionableAlerts';
import ActionInsight from './ActionInsight';
import AuditProvider from '../Audit/AuditProvider';
import AutopayScheduled from './AutopayScheduled';
import Billers from './Billers';
import Contacts from './Contacts';
import EBillEnrollments from './EBillEnrollments';
import History from './Transactions/History';
import NotificationsAndAlerts from './Notifications';
import OneTimePasscode from './OneTimePasscode';
import PayerDetailInvalidRequest from './PayerDetailInvalidRequest';
import PayerDetailSectionsProgress from './PayerDetailSectionsProgress';
import Profile from './Profile';
import Scheduled from './Transactions/Scheduled';
import Users from './Users';

const useStyles = (addHeight) => {
  return makeStyles((theme) => ({
    container: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
    },
    root: {
      contentVisibility: 'auto',
      minHeight: theme.breakpoints.values.sm / 2,
      overflowY: 'auto',
      ...(addHeight ? viewHeightWithoutAppbar(theme) : null),
    },
  }))();
};

const PayerDetailSections = ({ addHeight, fspId: fspIdProp, id: idProp }) => {
  const allIds = useSelector(executePrivFspsSelector, shallowEqual);
  const classes = useStyles(addHeight);
  const dispatch = useDispatch();
  const fspIdParam = useFSPId();
  const idParam = usePayerId();
  const ref = useRef();
  const id = idProp ?? idParam;
  const fspId = fspIdProp ?? fspIdParam;
  const user = useSelector(
    (state) => selectorFirstByPayerId(state, id),
    shallowEqual
  );

  const verified = allIds.find(({ id }) => id === fspId);

  useEffect(() => {
    if (verified) {
      dispatch(loadById({ fspId, id }));
      dispatch(loadUsersByPayerId({ fspId, payerId: id }));
    }
  }, [dispatch, fspId, id, verified]);

  useEffect(() => {
    if (verified && user.id) {
      dispatch(loadUserFSPConfig({ fspId, payerId: id, userId: user.id }));
    }
  }, [dispatch, fspId, id, verified, user.id]);

  useScrollState('pos', ref.current);

  return verified ? (
    <div ref={ref} className={classes.root}>
      <PayerDetailSectionsProgress />
      <div className={classes.container}>
        <AuditProvider>
          <Profile fspId={fspId} id={id} />
          <Users fspId={fspId} payerId={id} />
          <Accounts fspId={fspId} payerId={id} />
          <Billers fspId={fspId} payerId={id} />
          <EBillEnrollments fspId={fspId} payerId={id} />
          <AutopayScheduled fspId={fspId} payerId={id} />
          <Contacts fspId={fspId} payerId={id} />
          <ActionableAlerts fspId={fspId} payerId={id} />
          <NotificationsAndAlerts fspId={fspId} payerId={id} />
          <ActionInsight fspId={fspId} payerId={id} />
          <Scheduled fspId={fspId} payerId={id} />
          <History fspId={fspId} payerId={id} />
          <OneTimePasscode fspId={fspId} payerId={id} />
        </AuditProvider>
      </div>
    </div>
  ) : (
    <PayerDetailInvalidRequest payerId={id} />
  );
};

PayerDetailSections.propTypes = {
  addHeight: PropTypes.bool,
  fspId: PropTypes.number,
  id: PropTypes.number,
};

PayerDetailSections.defaultProps = {
  addHeight: true,
  fspId: undefined,
  id: undefined,
};

export default memo(PayerDetailSections);
