import { client } from 'util/sdk';

export const load = ({ fspId, payerId, limit, offset }) =>
  client.executeRequest(
    'GET',
    `/fsps/${fspId}/payers/${payerId}/payerebillernetebillers`,
    {
      queryParams: { limit, offset },
      returnModel: { name: 'payerzebillernetebiller' },
    }
  );

export const refresh = ({ fspId, ...data }) =>
  client.fsps(fspId).payerEbillerNotify.create(data);
