import { takeEvery } from 'redux-saga/effects';
import { load, loadById, update } from 'sdk/internalAccount';
import { LOAD, LOAD_ID, UPDATE } from 'model/internalAccount';
import { callAction, ORM } from '../util';

const formatSuccessMessage = ({ response }) => {
  return `${
    response.nickName || response.description || `${response.ddaType} account`
  } has been successfully updated`;
};

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({ api: load, dataName: ORM, type: LOAD })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({ api: loadById, dataName: ORM, type: LOAD_ID })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage,
      type: UPDATE,
    })
  );
}
