import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AuditDetailDay from './AuditDetailDay';
import AuditDetailRow from './AuditDetailRow';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3),
  },
  row: {
    marginLeft: theme.spacing(6),
  },
}));

const AuditDetail = ({ items, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid alignItems="flex-start" container direction="column">
        <Grid item zeroMinWidth>
          <AuditDetailDay {...props} />
        </Grid>
        <div className={classes.row}>
          {items.map(({ id, ...itemProps }, index) => (
            <Grid key={id} item>
              <AuditDetailRow
                lastEntry={items.length - 1 === index}
                {...props}
                {...itemProps}
              />
            </Grid>
          ))}
        </div>
      </Grid>
    </div>
  );
};

AuditDetail.propTypes = {
  items: PropTypes.array.isRequired,
  last: PropTypes.bool.isRequired,
};

export default AuditDetail;
