import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { find, head, propEq } from 'ramda';
import { Select } from 'components/common/fields';
import useKeepValue from 'hooks/useKeepValue';
import AdminActions from './AdminActions';
import DetailStatementView from './View/DetailStatementView';
import DetailStatementEdit from './Edit/DetailStatementEdit';

const mapOptions = ({ id, billOn }) => ({ label: billOn, value: id });
const headId = (statements) =>
  Array.isArray(statements) && statements.length ? head(statements).id : 0;

const DetailStatement = ({
  isCreditCardAccountType,
  billerAccountNumberMask,
  fspId,
  name,
  statements,
}) => {
  const [selectedId, setSelectedId] = useState(headId(statements));
  const [isInEditMode, setIsInEditMode] = useState(false);
  const openEditMode = () => setIsInEditMode(true);
  const closeEditMode = () => setIsInEditMode(false);
  const selectedStatement = find(propEq('id', selectedId), statements);
  const displayedStatement = useKeepValue(selectedStatement);

  useEffect(() => {
    if (!selectedStatement) {
      setSelectedId(headId(statements));
    }
  }, [statements, selectedStatement]);

  const options = statements.map(mapOptions);

  const handleStatementDateChange = (event) => {
    setSelectedId(event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          data={options}
          displayAttr="label"
          label="Statement date"
          onChange={handleStatementDateChange}
          value={selectedId}
          valueAttr="value"
        />
        <AdminActions
          fspId={fspId}
          openEditMode={openEditMode}
          statementId={selectedId}
        />
      </Grid>
      <Grid container item xs={12}>
        {!isInEditMode && (
          <DetailStatementView
            billerAccountNumberMask={billerAccountNumberMask}
            fspId={fspId}
            isCreditCardAccountType={isCreditCardAccountType}
            name={name}
            statement={displayedStatement}
          />
        )}
        {isInEditMode && (
          <DetailStatementEdit
            fspId={fspId}
            isCreditCardAccountType={isCreditCardAccountType}
            onClose={closeEditMode}
            statement={displayedStatement}
          />
        )}
      </Grid>
    </Grid>
  );
};

DetailStatement.propTypes = {
  billerAccountNumberMask: PropTypes.string.isRequired,
  isCreditCardAccountType: PropTypes.bool.isRequired,
  fspId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  statements: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DetailStatement;
