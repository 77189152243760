import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { addCustom, loadCustom as loadRules } from 'model/limitRule';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LimitsExpire from './LimitsExpire';
import LimitsTable from '../LimitsTable';
import { useLimits } from '../LimitsContext';

const handlePropagation = (event) => {
  event.stopPropagation();
};

const LimitsDialog = ({ onClose, ...props }) => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const { fspId, limit, limitType, limitRule, payerId } = useLimits();

  const handleCancelClose = (event) => {
    // a change may have been made and it is not a form so undo what we did
    dispatch(loadRules({ fspId, id: limit.id, payerId }));
    onClose(event);
  };
  const handleClick = () => {
    setDisabled(true);
    dispatch(
      addCustom({
        fspId,
        payerId,
        limitRule: {
          expireMonth:
            limitRule.expireMonth === null ? undefined : limitRule.expireMonth, // API does not treat null same as undefined
          expireYear:
            limitRule.expireYear === null ? undefined : limitRule.expireYear, // API does not treat null same as undefined
          dailyLimits: limitRule.dailyLimits,
          maximumTransaction: limitRule.maximumTransaction,
          minimumTransaction: limitRule.minimumTransaction,
          monthlyLimits: limitRule.monthlyLimits,
          weeklyLimits: limitRule.weeklyLimits,
        },
      })
    );
  };

  useEffect(() => {
    if (limit.refreshDerived) {
      onClose();
    }
  }, [limit, onClose]);

  useEffect(() => {
    if (disabled && limitRule.formChangedDerived) {
      setDisabled(false);
    }
  }, [disabled, limitRule.formChangedDerived]);

  useEffect(() => {
    if (limitRule.formError) {
      setDisabled(true);
    } else if (disabled && !limitRule.formError) {
      setDisabled(false);
    }
  }, [disabled, limitRule.formError]);

  return (
    <Dialog onClick={handlePropagation} onClose={handleCancelClose} {...props}>
      <DialogTitle>Set Custom Payer Limits</DialogTitle>
      <DialogContent>
        <LimitsExpire />
        <LimitsTable edit limitType={limitType} payerId={payerId} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCancelClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={disabled} onClick={handleClick}>
          Set
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LimitsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LimitsDialog;
