import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  CHECK_CODE,
  ELEC_OR_CHECK_CODE,
  PAYEE_STATUS,
  UNDELIVERABLE_CODE,
} from 'consts';
import { LOAD, LOAD_ID, UPDATE, UPDATE_CHECKED } from 'model/payee';
import { load, loadById, update } from 'sdk/payee';
import { pageSettingsName } from 'components/modules/paymentOps/BillerManagement/Biller/Detail/RightCard/Routes/Payees/consts';
import { pick } from 'ramda';
import { selectorByBillerIdSelected as selector } from 'selectors/payee';
import { update as updatePageSetting } from 'model/pageSetting';
import {
  callAction,
  createActionSuccess,
  getAuth as selectorAuth,
  ORM,
} from '../util';

const elecOrCheck = { [ELEC_OR_CHECK_CODE]: CHECK_CODE };
const payloadUndeliverable = { [PAYEE_STATUS]: UNDELIVERABLE_CODE };
const uspsAddressFields = ['street1', 'street2', 'city', 'state', 'zip'];
const getAddress = (address = {}) => {
  const uspsAddress = pick(uspsAddressFields, address);

  Object.entries(uspsAddress).forEach(([key, value]) => {
    if (value === '') {
      uspsAddress[key] = null;
    }
  });

  return { ...elecOrCheck, uspsAddress };
};

function* updateChecked(action) {
  const { address, billerId, duplicateOffOn, undeliverable } = action.data;
  const auth = yield select(selectorAuth);
  const payees = yield select((state) => selector(state, billerId));
  const payloadAddress = getAddress(address);

  const calls = payees.map(({ fspId, id: payeeId }) => {
    const params = undeliverable
      ? { duplicateOffOn, ...payloadUndeliverable }
      : payloadAddress;
    const payload = { fspId, payeeId, ...params };

    return call(update, payload, auth);
  });

  try {
    const result = yield all(calls);

    yield put(
      updatePageSetting({
        refreshedAt: Date.now(),
        type: pageSettingsName,
      })
    );

    yield put(createActionSuccess(UPDATE_CHECKED)(result, {}));
  } catch (error) {
    yield put({ error, type: UPDATE_CHECKED.FAILURE });
  }
}

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response: { name } }) =>
        `${name} has been successfully updated`,
      type: UPDATE,
    })
  );

  yield takeEvery(UPDATE_CHECKED.ACTION, updateChecked);
}
