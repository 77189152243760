import { attr, Model } from 'redux-orm';
import { evolve } from 'ramda';
import { currencyNoSymbolNoNA } from 'util/format';
import {
  A2A_IN_OFF_ON,
  A2A_OUT_OFF_ON,
  A2A_INTERNAL_DEFAULT_TO_OFF_ON,
  A2A_INTERNAL_DEFAULT_FROM_OFF_ON,
  BPX_OFF_ON,
  BP_EBILL_OFF_ON,
  BP_EXPEDITE_FEE_AMOUNT,
  BP_EXPEDITE_OFF_ON,
  BP_INVOICE_OFF_ON,
  BP_OFF_ON,
  INSIGHT_OFF_ON,
  INTERNAL_TRANSFER_OFF_ON,
  OTP_HIGH_RISK_BILL_PAY_AMOUNT,
  OTP_HIGH_RISK_P2P_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_TO_AMOUNT,
  OTP_OFF_ON,
  P2P_DIRECT_OFF_ON,
  P2P_OFF_ON,
  P2P_REALTIME_FEE_AMOUNT,
  P2P_REALTIME_OFF_ON,
  SSO_A2A_OFF_ON,
  SSO_BP_OFF_ON,
  SSO_P2P_OFF_ON,
  TYPE_CODE as TYPE,
} from 'consts';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

/**
 * 05/11/2022
 *
 * https://payrailz.atlassian.net/browse/DBAPI-1595
 * https://payrailz.atlassian.net/browse/ON-1535
 *
 * FSP Payer Types should not include these two fields
 *   - a2aGoodFundsOffOn
 *   - fiDirectOffOn
 *
 * Do not change the order of fields because the api requires that fields are
 * sent in this particular order when it comes to reset endpoints
 */
export const PayerTypeFormFields = {
  TYPE,
  OTP_OFF_ON,
  BP_OFF_ON,
  INSIGHT_OFF_ON,
  BP_EBILL_OFF_ON,
  BP_INVOICE_OFF_ON,
  BP_EXPEDITE_OFF_ON,
  BP_EXPEDITE_FEE_AMOUNT,
  A2A_IN_OFF_ON,
  A2A_OUT_OFF_ON,
  A2A_INTERNAL_DEFAULT_FROM_OFF_ON,
  A2A_INTERNAL_DEFAULT_TO_OFF_ON,
  P2P_OFF_ON,
  BPX_OFF_ON,
  SSO_BP_OFF_ON,
  SSO_A2A_OFF_ON,
  INTERNAL_TRANSFER_OFF_ON,
  SSO_P2P_OFF_ON,
  P2P_REALTIME_OFF_ON,
  P2P_REALTIME_FEE_AMOUNT,
  P2P_DIRECT_OFF_ON,
  OTP_HIGH_RISK_BILL_PAY_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_TO_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT,
  OTP_HIGH_RISK_P2P_AMOUNT,
};

const twoDecimals = (r) => r?.toFixed(2) ?? r;

const transform = {
  bpExpediteFeeAmount: currencyNoSymbolNoNA(),
  p2pRealtimeFeeAmount: currencyNoSymbolNoNA(),
  [OTP_HIGH_RISK_BILL_PAY_AMOUNT]: twoDecimals,
  [OTP_HIGH_RISK_P2P_AMOUNT]: twoDecimals,
  [OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT]: twoDecimals,
  [OTP_HIGH_RISK_TRANSFER_TO_AMOUNT]: twoDecimals,
};

export const addDerived = (data) => {
  return evolve(transform, data);
};

export default class PayerType extends Model {
  static get modelName() {
    return 'PayerType';
  }

  static reducer({ type, data }, PayerType) {
    const id = data?.props?.id;
    switch (type) {
      case ADD.SUCCESS:
        PayerType.create(addDerived(data?.response));
        break;

      case REMOVE.SUCCESS:
        if (PayerType.idExists(id)) {
          PayerType.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        PayerType.upsert(addDerived(data.response));
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      a2aInOffOn: attr(),
      a2aOutOffOn: attr(),
      defaultA2AInternalFromOffOn: attr(),
      defaultA2AInternalToOffOn: attr(),
      bpEbillOffOn: attr(),
      bpExpediteFeeAmount: attr(),
      bpExpediteOffOn: attr(),
      bpInvoiceOffOn: attr(),
      bpOffOn: attr(),
      bpxOffOn: attr(),
      createdAt: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insightOffOn: attr(),
      internalTransferOffOn: attr(),
      offset: attr(),
      otpOffOn: attr(),
      p2pDirectOffOn: attr(),
      p2pOffOn: attr(),
      p2pRealtimeFeeAmount: attr(),
      p2pRealtimeOffOn: attr(),
      ssoA2AOffOn: attr(),
      ssoBPOffOn: attr(),
      ssoP2POffOn: attr(),
      type: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = PayerType.modelName;

export const ADD = createAddConst(name);
export const IMPORT = createImportConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const importAction = createAction(IMPORT);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
