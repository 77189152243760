import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { update } from 'model/pageSetting';
import AuditContext from './AuditContext';
import AuditDrawer from './AuditDrawer';

const empty = () => () => {
  return null;
};
const emptyAction = () => () => ({ type: '' });
const emptyCountSelector = () => () => 0;
const emptySelector = () => () => [];

const AuditProvider = ({ children }) => {
  const [action, setAction] = useState(emptyAction);
  const [actionParams, setActionParams] = useState();
  const [actionType, setActionType] = useState('');
  const [actionBusyType, setActionBusyType] = useState('');
  const [countSelector, setCountSelector] = useState(emptyCountSelector);
  const [DrawerEndAdornment, setDrawerEndAdornment] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState({});
  const [Row, setRow] = useState(empty);
  const [selectorGroupedByDays, setSelectorGroupedByDays] =
    useState(emptySelector);
  const [selectorId, setSelectorId] = useState(emptySelector);
  const [selectorProps, setSelectorProps] = useState();
  const [Title, setTitle] = useState(empty);
  const dispatch = useDispatch();

  const value = useMemo(() => {
    const close = () => {
      dispatch(update({ payerId: item?.id, type: 'audit', page: 0 }));
      setIsOpen(false);
    };
    const open = ({
      action,
      actionParams,
      countSelector,
      DrawerEndAdornment,
      item,
      Row,
      selectorGroupedByDays,
      selectorId,
      selectorProps,
      Title,
    }) => {
      setAction(() => action);
      setActionParams(actionParams);
      setActionBusyType(action().busyType);
      setActionType(action().type);
      setCountSelector(() => countSelector);
      setDrawerEndAdornment(() => DrawerEndAdornment);
      setIsOpen(true);
      setItem(item);
      setRow(() => Row);
      setSelectorGroupedByDays(() => selectorGroupedByDays);
      setSelectorId(() => selectorId);
      setSelectorProps(() => selectorProps ?? { id: item.id });
      setTitle(() => Title);
    };

    return {
      action,
      actionParams,
      actionBusyType,
      actionType,
      close,
      countSelector,
      DrawerEndAdornment,
      isOpen,
      item,
      open,
      Row,
      selectorGroupedByDays,
      setActionBusyType,
      setActionType,
      setCountSelector,
      setSelectorGroupedByDays,
      setSelectorId,
      selectorId,
      selectorProps,
      setSelectorProps,
      setItem,
      setRow,
      setTitle,
      Title,
    };
  }, [
    action,
    actionParams,
    actionBusyType,
    actionType,
    countSelector,
    dispatch,
    DrawerEndAdornment,
    isOpen,
    item,
    Row,
    selectorGroupedByDays,
    selectorId,
    selectorProps,
    Title,
  ]);

  return (
    <AuditContext.Provider value={value}>
      <AuditDrawer />
      {children}
    </AuditContext.Provider>
  );
};

AuditProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuditProvider;
