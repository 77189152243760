import Payer from 'model/payer';
import { attr, fk, Model } from 'redux-orm';
import { head, omit } from 'ramda';
import { createAction, createRequestConst } from '../util';
import { getFrequency, getDeliveryMethod } from './util/transaction';

const derived = (item, isLoadedFromModelId = false) => {
  return {
    ...item,
    deliveryMethodDerived: getDeliveryMethod(item, true),
    frequencyLabel: getFrequency(item?.frequency),
    isLoadedFromModelId,
  };
};

export default class ScheduledTransaction extends Model {
  static get modelName() {
    return 'ScheduledTransaction';
  }

  static reducer({ type, data }, ScheduledTransaction, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data.props ? data.props.payerId : 0)) {
          Payer.withId(data.props.payerId).scheduledTransactions.delete();
        }
        if (data.response) {
          data.response.forEach((r) => ScheduledTransaction.upsert(derived(r)));
        }
        break;

      case LOAD_MODEL_ID.SUCCESS: {
        // If a scheduled transaction is changed while it is open in Ontrac,
        // it will be deleted and a new one inserted, so they will have different
        // ids. An upsert will introduce duplicates when a scheduled transaction
        // is changed while it is open in Ontrac. So we will delete anything with
        // the modelId of the first entry.
        const firstEntry = head(data.response);

        if (firstEntry) {
          ScheduledTransaction.filter(
            ({ modelId, payerId }) =>
              payerId === undefined && modelId === firstEntry.modelId
          ).delete();
        }

        data.response.forEach((r) =>
          ScheduledTransaction.upsert(derived(omit(['payerId'], r), true))
        );
        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      amount: attr(),
      amountDerived: attr(), // derived
      autopayRuleId: attr(),
      checkMemo: attr(),
      confirmationNumber: attr(),
      createdAt: attr(),
      ddaId: attr(),
      deliver: attr(),
      deliverOn: attr(),
      deliverOnDerived: attr(), // derived
      deliveryMethodDerived: attr(), // derived
      description: attr(),
      destination: attr(),
      destinationAccountSuffix: attr(), // derived
      destinationMask: attr(),
      endDateDerived: attr(), // derived
      externalDdaId: attr(),
      feeAmount: attr(),
      foreverOffOn: attr(),
      frequency: attr(),
      frequencyLabel: attr(), // derived
      fsp: attr(),
      fspId: attr(),
      fundingAccount: attr(),
      fundingAccountSuffix: attr(), // derived
      fundingMask: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      invoices: attr(), // optional entry in response - business payers only
      microService: attr(),
      modelId: attr(),
      offset: attr(),
      p2pDirectOffOn: attr(),
      payeeId: attr(),
      payer: attr(),
      payerId: fk(Payer.modelName, 'scheduledTransactions'),
      recipientId: attr(),
      recipientNote: attr(),
      recipientNoteEncodedOffOn: attr(),
      scheduleDeliverOn: attr(),
      scheduleSendOn: attr(),
      securityAnswer: attr(),
      securityQuestion: attr(),
      sendOn: attr(),
      sendOnDerived: attr(), // derived
      sendOrDeliver: attr(),
      status: attr(),
      statusDerived: attr(), // derived
      subtitle: attr(), // derived - used in Audit - https://payrailz.atlassian.net/browse/ON-536
      title: attr(), // derived - used in Audit - https://payrailz.atlassian.net/browse/ON-536
      transactionType: attr(),
      transactionTypeDerived: attr(), // derived
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const { modelName } = ScheduledTransaction;
export const LOAD = createRequestConst(modelName);
export const LOAD_MODEL_ID = createRequestConst(`${modelName}_model_id`);

export const loadByModelId = createAction(LOAD_MODEL_ID);
export const load = createAction(LOAD);
