import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';

const DetailSummary = ({
  isCreditCardAccountType,
  amountDue,
  dueOn,
  minimumAmount,
}) => {
  return (
    <Grid container item sm={8} xs={12}>
      <Grid container item xs={12}>
        <Grid item sm={6} xs={12}>
          <ReadOnlyFormControl label="Due date" value={dueOn} />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {isCreditCardAccountType && (
          <Grid item sm={6} xs={12}>
            <ReadOnlyFormControl label="Minimum due" value={minimumAmount} />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <ReadOnlyFormControl
            label={isCreditCardAccountType ? 'Balance' : 'Amount due'}
            value={amountDue}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailSummary.propTypes = {
  isCreditCardAccountType: PropTypes.bool.isRequired,
  amountDue: PropTypes.string.isRequired,
  dueOn: PropTypes.string.isRequired,
  minimumAmount: PropTypes.string.isRequired,
};

export default DetailSummary;
