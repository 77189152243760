import { evolve } from 'ramda';
import { dateAndTime } from 'util/index';
import { currencyNoSymbolNoNA } from 'util/format';

const common = {
  amount: currencyNoSymbolNoNA(),
  billPayAmount: currencyNoSymbolNoNA(),
  p2pAmount: currencyNoSymbolNoNA(),
  transferAmount: currencyNoSymbolNoNA(),
  transferFromAmount: currencyNoSymbolNoNA(),
  transferToAmount: currencyNoSymbolNoNA(),
};

const currencyTransformations = {
  dailyLimits: {
    ...common,
  },
  maximumTransaction: {
    ...common,
  },
  minimumTransaction: {
    ...common,
  },
  monthlyLimits: {
    ...common,
  },
  weeklyLimits: { ...common },
};

export default (r) => {
  r.updatedAtDerived = dateAndTime(r?.updatedAt);

  return evolve(currencyTransformations, r);
};
