import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { POSSIBLE_PRODUCTS } from 'consts';
import { isOn } from '../util';

const selector = createSelector(orm, ({ UserFspConfig }) => {
  return UserFspConfig.all().toRefArray();
});

export const selectUserFspConfigByFspIdPayerId = createReselectSelector(
  [selector, (state, fspId, payerId) => ({ fspId, payerId })],
  (userFspConfigs, { fspId, payerId }) => {
    const userFspConfig = userFspConfigs.find(
      (fspConfig) => fspConfig.fspId === fspId && fspConfig.payerId === payerId
    );
    return userFspConfig || {};
  }
);

export const makeUserFspConfigSelector = () =>
  selectUserFspConfigByFspIdPayerId;

export const makeSupportedTypesSelector = () =>
  createReselectSelector(selectUserFspConfigByFspIdPayerId, (userFspConfig) =>
    POSSIBLE_PRODUCTS.filter(({ type }) => isOn(userFspConfig?.[type]))
  );

export const makeEBillEnabledSelector = () =>
  createReselectSelector(selectUserFspConfigByFspIdPayerId, (userFspConfig) => {
    return isOn(userFspConfig?.bpConfig?.bpEbillOffOn);
  });

export const otpConfig = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) => userFspConfig?.otpConfig
);

export const otpEnabled = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) => isOn(userFspConfig?.otpOffOn)
);

export const creditPayOnSelector = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) => isOn(userFspConfig?.creditPayOffOn)
);

export const insightOnSelector = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) => isOn(userFspConfig?.insightOffOn)
);

export const transactOnBehalfA2AOnSelector = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) =>
    isOn(userFspConfig?.transactOnBehalfConfig?.transactA2AOffOn)
);

export const transactOnBehalfBPOnSelector = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) =>
    isOn(userFspConfig?.transactOnBehalfConfig?.transactBPOffOn)
);

export const transactOnBehalfP2POnSelector = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) =>
    isOn(userFspConfig?.transactOnBehalfConfig?.transactP2POffOn)
);

export const p2pDirectEnabledSelector = createReselectSelector(
  selectUserFspConfigByFspIdPayerId,
  (userFspConfig) => isOn(userFspConfig?.p2pConfig?.p2pDirectOffOn)
);
