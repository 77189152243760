import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import AuditContext from './AuditContext';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    borderLeft: '3px solid rgba(115,34,130, .25)',
    left: -1.5,
    position: 'relative',
  },
  gridContainerLast: {
    borderImage:
      'linear-gradient(to bottom, rgba(115,34,130, .25) 0%,rgba(115,34,130, .25) 50%,rgba(115,34,130, .25) 50%,rgba(0,0,0,0) 50%)',
    borderImageSlice: 1,
  },
  gridItem: {
    marginTop: theme.spacing(1),
  },
  gridLast: {
    marginBottom: theme.spacing(1),
  },
  thumb: {
    left: -1.1,
    width: 12,
    height: 12,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    backgroundColor: '#732282',
  },
}));

const AuditDetailRow = ({ last, lastEntry, ...props }) => {
  const classes = useStyles();
  const { Row } = useContext(AuditContext);

  return (
    <Grid
      alignItems="center"
      className={classNames(classes.gridContainer, {
        [classes.gridContainerLast]: last && lastEntry,
      })}
      container
      wrap="nowrap"
    >
      <Grid item>
        <div className={classes.thumb} />
      </Grid>
      <Grid
        className={classNames(classes.gridItem, {
          [classes.gridLast]: lastEntry,
        })}
        item
      >
        <Row {...props} />
      </Grid>
    </Grid>
  );
};

AuditDetailRow.propTypes = {
  last: PropTypes.bool.isRequired,
  lastEntry: PropTypes.bool.isRequired,
};

export default AuditDetailRow;
