import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ } from './consts';

const useStyles = makeStyles(() => ({
  text: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
}));

const NoOptionsText = ({ idSearch, text, value }) => {
  const classes = useStyles();

  return idSearch && value.length < NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ ? (
    `A minimum of ${NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ} characters are needed`
  ) : (
    <span>
      {text}
      <span className={classes.text}>{value}</span>
    </span>
  );
};

NoOptionsText.propTypes = {
  idSearch: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.string,
};

NoOptionsText.defaultProps = {
  idSearch: false,
  text: '',
  value: '',
};

export default NoOptionsText;
